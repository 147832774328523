export function humanizeFollowersCount(count) {
    const ranges = [
        [0, '<1K'],
        [1_000, '1K+'],
        [10_000, '10K+'],
        [50_000, '50K+'],
        [100_000, '100K+'],
        [500_000, '500K+'],
        [1_000_000, '1M+'],
        [5_000_000, '5M+'],
        [10_000_000, '10M+'],
        [50_000_000, '50M+'],
        [100_000_000, '100M+'],
    ];

    for (let [c, label] of ranges.reverse()) {
        if (count >= c)
            return label;
    }
}


export function truncateText(str, n, html=true){
    return (str.length > n) ? str.substr(0, n-1) + (html ? '&hellip;' : '…') : str;
};


export function byteArrayToInt(byteArray) {
    var x = 0n;
    for (var b of byteArray) {
        x = (x * 256n) + BigInt(b);
    }
    return x;
}


export function bytesToSpotifyId(bytes) {
    var base62 = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var x = byteArrayToInt(bytes);

    var chars = []
    for(var i = 0; i < 22; i++){
        var c = x % 62n
        chars = base62[c] + chars;

        x = (x - c) / 62n;
    }
    return chars;
}


export const debounce = (func, delay) => {
    let timerId;
    function debounced() {
        clearTimeout(timerId);
        timerId = setTimeout(() => func.apply(this, arguments), delay);
    };

    return debounced;
};


export function randomChoice(choices) {
    var index = Math.floor(Math.random() * choices.length);
    return choices[index];
}
