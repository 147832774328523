// forked from https://github.com/Fil/d3-inertia

import { select } from "d3-selection";
import { timer } from "d3-timer";
import { vec2 } from 'gl-matrix'
import { Tween, Easing } from '@tweenjs/tween.js';

export function inertiaPanZoom(target, render, sync, opt) {
    if (!opt) opt = {};
    if (target.node) target = target.node();
    target = select(target);

    let position = [0, 0], startposition = [0, 0], endposition = [0, 0];

    var inertia = inertiaHelper({
        start: () => {
            startposition = inertia.position;
        },
        move: () => {
            inertia.p.x = - (position[0] + inertia.position[0] - startposition[0]);
            inertia.p.y = - (position[1] + inertia.position[1] - startposition[1]);
            render && render(inertia);
        },
        stop: () => {
            endposition = position = [
                position[0] + inertia.position[0] - startposition[0], 
                position[1] + inertia.position[1] - startposition[1],
            ];
        },
        end: () => {
            endposition = [
                position[0] + inertia.position[0] - startposition[0], 
                position[1] + inertia.position[1] - startposition[1],
            ];
        },
        glide: (t) => {
            position = [
                endposition[0] + t * inertia.velocity[0], 
                endposition[1] + t * inertia.velocity[1],
            ];

            inertia.p.x = - position[0];
            inertia.p.y = - position[1];
            render && render(inertia);
        },
        render: render,
        sync: sync,
        glideTime: opt.glideTime || 2000, // reference time in ms
        zoomTime: opt.zoomTime || 200,
    });

    inertia.p = {
      x: 0,
      y: 0,
      vx: 0,
      vy: 0,
      k: 1,
    }

    inertia.enabled = true;
    inertia.zooming = false;
    inertia.zoomTween = null;

    inertia.setCenter = (x, y) => {
      inertia.p.x = x;
      inertia.p.y = y;
      position = [-x, -y];
      render && render(inertia);
      sync && sync(inertia.p);
    }

    inertia.setZoom = (k, duration) => {
      if (duration > 0) {
        tweenZoom({k: k}, inertia, render, sync, duration, Easing.Quadratic.InOut);
      } else {
        inertia.p.k = k;
        render && render(inertia);
        sync && sync(inertia.p);
      }
    }

    return inertia;
}


function tweenZoom(targetP, inertia, render, sync, duration, easing) {
    if (inertia.zoomTween){
      inertia.zoomTween.stop();
    }

    inertia._target = targetP;
    inertia.zoomTween = new Tween(inertia.p)
      .easing(easing)
      .to(inertia._target, duration)
      .onStart(() => {
        inertia.zooming = true;
      })
      .onUpdate((p) => {
        inertia.position = [-p.x, -p.y];
        render && render(inertia);
      })
      .onComplete((p) => {
        inertia.zoomTween = null;
        inertia.zooming = false;
        sync & sync(p);
      })
      .start();
}

export function inertiaHelper(opt) {
  var A = opt.glideTime;
  var limit = 1.0001;
  var B = -Math.log(1 - 1 / limit);
  var inertia = {
    start: ({x, y, k}) => {
      var position = [x, y];
      inertia.position = position;
      inertia.velocity = [0, 0];
      inertia.timer.stop();
      opt.start && opt.start(position);

      if (!inertia.zooming) {
        inertia.p.k = k;
        opt.sync && opt.sync(inertia.p);
      }
    },
    move: ({x, y, k}, eventType) => {     
      var position = [x, y];
      let v = [0, 0];
      var time = performance.now();
      
      const targetZoomChanged = inertia.zoomTween? k != inertia._target.k : k != inertia.p.k;

      if (eventType.startsWith("zoom") && !targetZoomChanged) {
        return;
      }

      if (eventType == "zoom-big") {
        // smooth mouse wheel zoom
        tweenZoom({x: -x, y: -y, k: k}, inertia, opt.render, opt.sync, opt.zoomTime, Easing.Quadratic.Out);
        return;
      }
      
      if (inertia.zooming){
        // stop zooming
        inertia.zoomTween.stop();
        inertia.zoomTween = null;
        inertia.zooming = false;
        opt.sync & opt.sync(inertia.p);
        return
      }

      if ((eventType == "touch" && targetZoomChanged) || (eventType == "zoom-small")) {
        inertia.position = position;
        inertia.p.k = k;
        opt.move && opt.move(position);
        opt.sync & opt.sync(inertia.p);
        return;
      }

      var deltaTime = time - inertia.time;
      var decay = 1 - Math.exp(-deltaTime / 1000);
      v = inertia.velocity.map(function(d, i) {
        var deltaPos = position[i] - inertia.position[i];
        return 100 * (1 - decay) * deltaPos / deltaTime + d * decay;
      });
      const maxVelocity = 500;
      if (vec2.length(v) > maxVelocity) {
        vec2.normalize(v, v);
        vec2.scale(v, v, maxVelocity);
      }

      inertia.velocity = v;
      inertia.time = time;
      inertia.position = position;
      inertia.p.k = k;
      opt.move && opt.move(position);
      opt.sync & opt.sync(inertia.p);
    },
    end: () => {
      var v = inertia.velocity;
      if (vec2.sqrLen(v) < 100) return inertia.timer.stop(), opt.stop && opt.stop();

      var time = performance.now();
      var deltaTime = time - inertia.time;
      
      if (opt.hold == undefined) opt.hold = 50; // default flick->drag threshold time (0 disables inertia)
      
      if (deltaTime >= opt.hold) return inertia.timer.stop(), opt.stop && opt.stop();

      opt.end && opt.end();

      inertia.timer.restart(function(e) {
        inertia.t = limit * (1 - Math.exp(-B * e / A));
        opt.glide && opt.glide(inertia.t);
        if (inertia.t > 1) {
          inertia.timer.stop();
          inertia.velocity = [0, 0];
          inertia.t = 1;
          opt.finish && opt.finish();
          opt.sync & opt.sync(inertia.p);
        }
      });
      opt.sync & opt.sync(inertia.p);
    },
    position: [0, 0],
    velocity: [0, 0], // in pixels/s
    timer: timer(function(){}),
    time: 0
  };

  inertia.timer.stop();

  return inertia;
}