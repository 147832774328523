import { select } from "d3-selection";


export async function loadHtml(url) {
    var response = await fetch(url, {
        method: 'GET',
        headers : { "Content-Type" : "text/html" },
    });
    const html = await response.text();
    const wrapper = document.createElement("div");
    wrapper.innerHTML = html;
    return select(wrapper.firstChild);
}
