export class Artist {
    constructor(data) {
        this.populate(data);
    }

    populate(data) {
        this.id = data.id ?? this.id;
        this.name = data.name ?? this.name;
        this.weight = data.weight ?? this.weight;
        this.followers = data.followers ?? this.followers;
        this.popularity = data.popularity ?? this.popularity;
        this.images = data.images ?? this.images;
        this.genres = data.genres ?? this.genres;

        // sort images from smallest to largest
        this.images?.sort((a, b) => a.height - b.height);
    }

    get imageUrlLarge() {
        // return the largest image
        if (this.images?.length > 0) {
            return this.images[this.images.length - 1].url;
        }
    }

    get imageUrlSmall() {
        // return the smallest image that is larger than 100x100
        for (let img of this.images) {
            if (img.width > 100 && img.height > 100) {
                return img.url;
            }
        }

        // return largest image as a fallback if no image larger than 100x100 is found
        return this.imageUrlLarge;
    }

    get _detailed() {
        return this.images !== undefined;
    }

    data() {
        return {
            id: this.id,
            name: this.name,
            weight: this.weight,
            followers: this.followers,
            popularity: this.popularity,
            images: this.images,
            genres: this.genres,
        }
    }
}

export class Connection {
    constructor(data) {
        this.populate(data);
    }

    populate(data) {
        this.artistAId = data.artistAId ?? data.artist_a_id ?? this.artistAId;
        this.artistBId = data.artistBId ?? data.artist_b_id ?? this.artistBId;
        this.numTracks = data.numTracks ?? data.num_tracks ?? this.numTracks;
        this.trackIds = data.trackIds ?? data.track_ids ?? this.trackIds;
        this.id = JSON.stringify([this.artistAId, this.artistBId]);
    }

    bind(artists, tracks) {
        this._artists = artists;
        this._tracks = tracks;
    }

    get artistA() {
        return this._artists.get(this.artistAId);
    }

    get artistB() {
        return this._artists.get(this.artistBId);
    }

    get tracks() {
        return this.trackIds.map(trackId => this._tracks.get(trackId));
    }

    data() {
        return {
            id: this.id,
            artistAId: this.artistAId,
            artistBId: this.artistBId,
            numTracks: this.numTracks,
            trackIds: this.trackIds,
        }
    }
}


export class Track {
    constructor(data) {
        this.populate(data);
    }

    populate(data) {
        this.id = data.id ?? this.id;
        this.name = data.name ?? this.name;
        this.popularity = data.popularity ?? this.popularity;
        this.previewUrl = data.previewUrl ?? data.preview_url ?? this.previewUrl;
        this.releaseDate = data.releaseDate ?? new Date(data.release_date) ?? this.releaseDate;
        this.artistIds = data.artistIds ?? data.artists.map(artistData => artistData.id) ?? this.artistIds;
    }

    bind(artists) {
        this._artists = artists;
    }

    get artists() {
        return this.artistIds.map(artistId => this._artists.get(artistId));
    }

    get releaseYear() {
        return this.releaseDate.getFullYear();
    }

    data() {
        return {
            id: this.id,
            name: this.name,
            popularity: this.popularity,
            previewUrl: this.previewUrl,
            releaseDate: this.releaseDate,
            artistIds: this.artistIds,
        }
    }
}

