import { select } from 'd3-selection';
import { debounce } from './utils.js';

export function setupSearch(onArtistClick, provider) {
    var searchWrapper = select('#artist-search-wrapper');
    var searchBox = searchWrapper.select('#artist-search-box');
    var searchResultsContainer = searchWrapper.select('#artist-search-results');
    var searchResultsList = searchResultsContainer.select('ul');
    var searchNoResults = searchResultsContainer.select('.no-results');

    searchWrapper
        .on('focusin', (event) => {
            var height = searchResultsList.node().scrollHeight + searchNoResults.node().scrollHeight;
            searchResultsContainer.style('height', height + 'px');
        })
        .on('focusout', (event) => {
            if(!searchWrapper.select(':hover').empty())  // we clicked on the searchWrapper, so we won't count it as mouseout
                return;

            searchResultsContainer.style('height', '0');
            var focus = searchResultsList.select('li.focus');
            if (!focus.empty())
                focus.classed('focus', false);
        });


    window.onkeydown = (event) => {
        var searching = document.activeElement == searchBox.node();

        if (!searching)
            return;

        switch(event.code){
            case "Enter":
                var focus = searchResultsList.select('li.focus');
                if (focus.empty())
                    break;

                focus.on("click")(event, focus.data()[0]);  // simulate click

                break;
            case "ArrowUp":
                var prevFocus = searchResultsList.select('li.focus');
                var nextFocus = select(prevFocus.node().previousElementSibling);
                if (nextFocus.empty())
                    break;

                prevFocus.classed('focus', false);
                nextFocus.classed('focus', true);
                break;    
            case "ArrowDown":
                var prevFocus = searchResultsList.select('li.focus');
                if (prevFocus.empty()) {
                    var nextFocus = searchResultsList.select('li');
                } else {
                    var nextFocus = select(prevFocus.node().nextElementSibling);
                }

                if (nextFocus.empty())
                    break;

                prevFocus.classed('focus', false);
                nextFocus.classed('focus', true);
                break; 
            default:
                break;
        }
    }

    async function searchArtists(query) {
        const artists = await provider.searchArtists(query);
        return artists;
    }

    function showSearchResults(artists, query) {
        if (!query || query != searchBox.property('value')) {
            searchNoResults.style('display', 'none');
            artists = [];
        } else if (!artists || artists.length == 0) {
            searchNoResults.style('display', 'inline');
            artists = [];
        } else {
            searchNoResults.style('display', 'none');
        }

        searchResultsList.selectAll('li')
            .data(artists, artist => artist.id)
            .join(
                enter => enter.append('li')
                    .classed("artist-row", true)
                    .call(li => {
                        li.append('div')
                            .attr("class", "image tiny")
                            .html(artist => {
                                if (artist.imageUrlSmall)
                                    return `<img src="${artist.imageUrlSmall}"></img>`;
                                else
                                    return `<img class="placeholder"></img>`;
                            });

                        li.append('div')
                            .text(artist => artist.name);
                    })
                    .on('click', (event, artist) => {
                        onArtistClick && onArtistClick(artist);
                        searchBox.property('value', '');
                        searchBox.node().blur();
                        showSearchResults();
                    })
                    .on('mouseover', function() {
                        var focus = searchResultsList.select('li.focus');
                        if (!focus.empty())
                            focus.classed('focus', false);

                        select(this).classed('focus', true);
                    })
                    .on('mouseout', function() {
                        select(this).classed('focus', false);
                    })
                    .style('opacity', 0)
                    .transition().duration(200).style('opacity', 1)
                , update => update
                , exit => exit.remove()
            );
            // .classed('focus', (d, i) => i == 0);  // this would select first item by default. but we won't select any item by default.

        var height = searchResultsList.node().scrollHeight + searchNoResults.node().scrollHeight;
        searchResultsContainer.style('height', height + 'px');
    }


    var searchDebounced = debounce(query => {
        searchArtists(query).then(results => showSearchResults(results, query));
    }, 500);


    searchBox.on('input', (event) => {
        var query = searchBox.property('value');

        if (query.length >= 3) {
            searchDebounced(query);
        } else {
            showSearchResults();
        }
    });
}
