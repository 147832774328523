import { loadHtml } from "../xhr.js";
import { Card } from "./base.js"
import cardHtmlUrl from "url:../../templates/cards/welcome.html";
// import { DateTime } from "luxon";

export class WelcomeCard extends Card {
    static display = "flex";

    constructor() {
        super();
    }

    async init(dataLastUpdate) {
        const div = await loadHtml(cardHtmlUrl);

        if (dataLastUpdate) {
            // const dataLastUpdateText = `${dataLastUpdate.toRelative()} (${dataLastUpdate.toLocaleString(DateTime.DATE_SHORT)})`;
            const dataLastUpdateText = `on ${dataLastUpdate.toFormat("MMMM yyyy")}`;
            
            div.select("#data-last-update")
                .style("display", "inline")
                .select("#value").text(dataLastUpdateText);
        }

        this.setDiv(div);
        return this;
    }
}
