export class AudioManager {
    constructor() {
        this.currentButton = null;
    }

    stopCurrentAudio() {
        if (this.currentButton) {
            this.currentButton._playing = false;
            this.currentButton.audio.pause();
            this.currentButton.onPause() && this.currentButton.onPause();
            this.currentButton = null;
        }
    }

    bindButton(button, audioUrl, onPlay, onPause) {
        button.audio = new Audio(audioUrl);
        button.onPlay = onPlay;
        button.onPause = onPause;
        button.audio.addEventListener("ended", () => {
            button._playing = false;
            button.onPause && button.onPause();
            this.currentButton = null;
        });

        button.on("click", (e) => {
            if (!button._playing) {
                this.stopCurrentAudio();
                this.currentButton = button;
                button.audio.play()
                button._playing = true;
                button.onPlay && button.onPlay();
            } else {
                button._playing = false;
                button.audio.pause();
                button.onPause() && button.onPause();
                this.currentButton = null;
            }

            e.preventDefault();
        });
    }
}
