import { Graph, Node, UndirectedLink } from "./graph/graph";
import { LocalDB } from "./local-db";
import { Artist, Connection, Track } from "./models";
import { DateTime } from "luxon";
import { PRODUCTION_SERVER_BASE_URL, DEVELOPMENT_SERVER_BASE_URL } from "./config.js";

var BASE_URL;

if (process.env.NODE_ENV === 'production') {
	BASE_URL = PRODUCTION_SERVER_BASE_URL;
} else {
    BASE_URL = DEVELOPMENT_SERVER_BASE_URL;
}

export class DataProvider {
    constructor() {
        this.artists = new Map();
        this.tracks = new Map();
        this.completeGraph = new Graph();
        this.localDB = new LocalDB();
    }

    async init() {
        const metadata = await this.fetch("/metadata");
        window.serverDataDate = DateTime.fromISO(metadata.data_last_updated);
        // TODO: if server metadata data_last_updated date is different from localDB date: clean localDB.

        // pull data from local DB to memory
        const artists = await this.localDB.getArtists();
        artists.forEach(artist => {
            this.artists.set(artist.id, artist);

            const node = new Node(artist.id, artist.name, artist);
            this.completeGraph.addNode(node);
        });

        // pull all connections from local DB to memory
        const connections = await this.localDB.getConnections();
        connections.forEach(connection => {
            connection.bind(this.artists, this.tracks);

            const link = new UndirectedLink(connection.artistAId, connection.artistBId, connection);
            this.completeGraph.addLink(link);
        });

        // pull all expanded artists from local DB to memory
        const expandedArtistIds = await this.localDB.getExpandedArtistIds()
        expandedArtistIds.forEach(artistId => {
            this.completeGraph.markNodeExpanded(artistId);
        });

        // pull all tracks from local DB to memory
        const tracks = await this.localDB.getTracks();
        tracks.forEach(track => {
            track.bind(this.artists);
            this.tracks.set(track.id, track);
        });
    }

    async fetch(endpoint) {
        const response = await fetch(BASE_URL + endpoint, {
            method: "GET",
        });
        return await response.json();
    }

    async getSubgraph(artistId) {
        if (this.artists.has(artistId) && this.completeGraph.nodeIsExpanded(artistId)) {
            // subgraph is in memory
            // return copy so that node properties (position, animations...)
            // are not stored in this.completeGraph's nodes
            return this.completeGraph.neighborhoodGraph(artistId).copy();
        }

        const {artists: artistsData, connections: connectionsData} = await this.fetch("/subgraph/" + artistId);

        const subgraph = new Graph();
        const newArtists = [];
        const newConnections = [];

        artistsData.forEach(artistData => {
            var artist;
            if (this.artists.has(artistData.id)) {
                // artist is in memory
                artist = this.artists.get(artistData.id);
            } else {
                artist = new Artist(artistData);
                this.artists.set(artist.id, artist);
                newArtists.push(artist);
            }

            const node = new Node(artist.id, artist.name, artist);
            this.completeGraph.addNode(node);
            subgraph.addNode(node);

            if (node.id == artistId) {
                this.completeGraph.markNodeExpanded(node.id);
                subgraph.markNodeExpanded(node.id);
            }
        });

        // persist artists to local DB
        await this.localDB.putArtists(newArtists);

        connectionsData.forEach(connectionData => {
            var connection = new Connection(connectionData);
            connection.bind(this.artists, this.tracks);
            newConnections.push(connection);

            const link = new UndirectedLink(connection.artistAId, connection.artistBId, connection);
            this.completeGraph.addLink(link);
            subgraph.addLink(link);
        });

        // persist connections to local DB
        await this.localDB.putConnections(newConnections);

        // mark artist as expanded in local DB
        await this.localDB.markArtistExpanded(artistId)

        // return copy so that node properties (position, animations...)
        // are not stored in this.completeGraph's nodes
        return subgraph.copy();
    }


    async loadArtistsInfo(artistIds) { 
        const knownArtists = await Promise.all(artistIds.map(async artistId => {
            var artist = { id: artistId };
            if (this.artists.has(artistId)) {
                // artist is in memory
                artist = this.artists.get(artistId);
            }

            return artist;
        }));

        const artistIdsToFetch = knownArtists.filter(artist => !artist?._detailed).map(artist => artist.id);

        if (artistIdsToFetch.length == 0) {
            return;
        }

        const fetchedArtistsData = await this.fetch("/artists?artist_ids=" + artistIdsToFetch.join(","));

        fetchedArtistsData.forEach(artistData => {
            var artist;
            if (this.artists.has(artistData.id)) {
                // artist is in memory (but with only basic info)
                artist = this.artists.get(artistData.id);
                artist.populate(artistData);
            } else {
                artist = new Artist(artistData);
                this.artists.set(artist.id, artist);
            }
        });

        const fetchedArtists = fetchedArtistsData.map(artistData => this.artists.get(artistData.id));

        // persist artists to local DB
        await this.localDB.putArtists(fetchedArtists);
    }

    async loadTracksInfo(trackIds) { 
        const knownTracks = await Promise.all(trackIds.map(async trackId => {
            var track = { id: trackId };
            if (this.tracks.has(trackId)) {
                // tracks is in memory
                track = this.tracks.get(trackId);
            }

            return track;
        }));

        const trackIdsToFetch = knownTracks.filter(track => track?.name === undefined).map(track => track.id);

        if (trackIdsToFetch.length == 0) {
            return;
        }

        const fetchedTracksData = await this.fetch("/tracks?track_ids=" + trackIdsToFetch.join(","));       
        
        fetchedTracksData.forEach(async (trackData) => {
            const track = new Track(trackData);
            track.bind(this.artists);

            // put track in memory
            this.tracks.set(track.id, track);
        });

        const fetchedTracks = fetchedTracksData.map(trackData => this.tracks.get(trackData.id));

        // persist tracks to local DB
        await this.localDB.putTracks(fetchedTracks);
    }

    async searchArtists(q) {
        const artistsData = await this.fetch("/search-artists?q=" + q);
        artistsData.forEach(artistData => {
            if (this.artists.has(artistData.id)){
                // artist is in memory (the new one returned won't have more information)
            } else {
                const artist = new Artist(artistData);
                // put artist in memory
                this.artists.set(artist.id, artist);
            }
        });

        const resultArtists = artistsData.map(artistData => this.artists.get(artistData.id));

        await this.loadArtistsInfo(resultArtists.map(artist => artist.id));

        return resultArtists;
    }

    getArtist(artistId) {
        if (!this.artists.has(artistId)) {
            throw new Error("Artist not known. Make sure to load it first.")
        }

        return this.artists.get(artistId);
    }

    getTrack(trackId) {
        if (!this.tracks.has(trackId)) {
            throw new Error("Track not known. Make sure to load it first.")
        }

        return tracks.get(trackId);
    }

    getArtistNeighbors(artistId) {
        if (this.completeGraph.getNode(artistId) == undefined) {
            throw new Error("Artist not found in graph.")
        }

        const nodeNeighbors = this.completeGraph.nodeNeighbors(artistId);

        return Array.from(nodeNeighbors).map(node => node.obj);
    }
}
