import { select } from "d3-selection";


export class Card {
    static display = "block";

    constructor() {
        this.div = undefined;
    }

    setLoading() {
        const wrapper = document.createElement("div");
        wrapper.innerHTML = `<div class="card loading"><div class="loading-dual-ring"></div></div>`;
        this.setDiv(select(wrapper.firstChild));
    }

    setDiv(div) {
        this.div = div;
        window.cardManager.refreshCard(this);
    }

    onClose() {}
}
