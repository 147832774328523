/*
 * Based on: https://github.com/lragnarsson/WebGL-BatchDraw
 */

import { vec2, mat3 } from 'gl-matrix'

export class BatchDrawer {
    constructor(canvas, params) {
        // Get optional parameters or defaults
        this._startTime = Date.now();
        this.canvas = canvas;
        this.debug = params.debug ?? false;
        this.maxLines = params.maxLines ?? 10000;
        this.maxCircles = params.maxCircles ?? 10000;
        this.clearColor = params.clearColor ?? {r: 0, g: 0, b: 0, a: 1};

        // Init variables
        this.error = null;
        this.numLines = 0;
        this.numCircles = 0;
        this.maxId = 0;
        this.objMap = new Map();
        this.viewScale = 1;
        this.viewCenter = {x: 0, y: 0};
        this.viewMatrix = mat3.create();

        if (!this._initGLContext()) {
            return;
        }

        // Define attribute locations:
        this.LINE_VX_BUF = 0;
        this.LINE_START_BUF = 1;
        this.LINE_END_BUF = 2;
        this.LINE_WIDTH_BUF = 3;
        this.LINE_COLOR_BUF = 4;
        this.LINE_ID_BUF = 5;

        this.CIRCLE_VX_BUF = 0;
        this.CIRCLE_CENTER_BUF = 1;
        this.CIRCLE_RADIUS_BUF = 2;
        this.CIRCLE_FILL_COLOR_BUF = 3;
        this.CIRCLE_STROKE_COLOR_BUF = 4;
        this.CIRCLE_STROKE_WIDTH_BUF = 5;
        this.CIRCLE_OPACITY_BUF = 6;
        this.CIRCLE_ID_BUF = 7;

        this._initShadow();

        this.shadowLineProgram = this._initLineShaders(true);
        if (!this.shadowLineProgram) return;

        this.lineProgram = this._initLineShaders(false);
        if (!this.lineProgram) return;

        this.shadowCircleProgram = this._initCircleShaders(true);
        if (!this.shadowCircleProgram) return;

        this.circleProgram = this._initCircleShaders(false);
        if (!this.circleProgram) return;

        this.GL.clearColor(this.clearColor.r, this.clearColor.g, this.clearColor.b, this.clearColor.a);

        this.GL.enable(this.GL.BLEND);
        this.GL.blendEquation(this.GL.FUNC_ADD);

        this._initBuffers();

        this.resize(this.canvas.width, this.canvas.height);
    }


    _initGLContext() {
        this.GL = null;

        // Attempt to get a WebGL 2 context:
        try {
            this.GL = this.canvas.getContext("webgl2", { premultipliedAlpha: true, antialias: true, preserveDrawingBuffer: true });
        } catch(e) {
            console.log("Could not create a WebGL2 context.");
            return false;
        }

        return true;
    }


    _initBuffers() {
        // Initialize constant vertex positions for lines and circles (just a square).
        this.lineVertexBuffer = this._initArrayBuffer(new Float32Array([-0.5,  0.5,  1.0,
                                                                        -0.5, -0.5,  1.0,
                                                                         0.5,  0.5,  1.0,
                                                                         0.5, -0.5,  1.0]));

        this.circleVertexBuffer = this._initArrayBuffer(new Float32Array([-1.0,  1.0,  1.0,
                                                                          -1.0, -1.0,  1.0,
                                                                           1.0,  1.0,  1.0,
                                                                           1.0, -1.0,  1.0]));

        // Initialize Float32Arrays for CPU storage:
        this.lineStartArray = new Float32Array(this.maxLines * 2);
        this.lineEndArray = new Float32Array(this.maxLines * 2);
        this.lineWidthArray = new Float32Array(this.maxLines * 1);
        this.lineColorArray = new Float32Array(this.maxLines * 4);
        this.lineIdArray = new Float32Array(this.maxLines * 4);  // TODO: could be a Uint8Array ?

        this.circleCenterArray = new Float32Array(this.maxCircles * 2);
        this.circleRadiusArray = new Float32Array(this.maxCircles * 1);
        this.circleFillColorArray = new Float32Array(this.maxCircles * 3);
        this.circleStrokeColorArray = new Float32Array(this.maxCircles * 3);
        this.circleStrokeWidthArray = new Float32Array(this.maxCircles * 1);
        this.circleOpacityArray = new Float32Array(this.maxCircles * 1);
        this.circleIdArray = new Float32Array(this.maxCircles * 4);  // TODO: could be a Uint8Array ?

        // Initialize Empty WebGL buffers:
        this.lineStartBuffer = this._initArrayBuffer(this.lineStartArray, 2);
        this.lineEndBuffer = this._initArrayBuffer(this.lineEndArray, 2);
        this.lineWidthBuffer = this._initArrayBuffer(this.lineWidthArray, 1);
        this.lineColorBuffer = this._initArrayBuffer(this.lineColorArray, 4);
        this.lineIdBuffer = this._initArrayBuffer(this.lineIdArray, 4);

        this.circleCenterBuffer = this._initArrayBuffer(this.circleCenterArray, 2);
        this.circleRadiusBuffer = this._initArrayBuffer(this.circleRadiusArray, 1);
        this.circleFillColorBuffer = this._initArrayBuffer(this.circleFillColorArray, 3);
        this.circleStrokeColorBuffer = this._initArrayBuffer(this.circleStrokeColorArray, 3);
        this.circleStrokeWidthBuffer = this._initArrayBuffer(this.circleStrokeWidthArray, 1);
        this.circleOpacityBuffer = this._initArrayBuffer(this.circleOpacityArray, 1);
        this.circleIdBuffer = this._initArrayBuffer(this.circleIdArray, 4);
    }


    _initArrayBuffer(data) {
        let buffer = this.GL.createBuffer();
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, buffer);
        this.GL.bufferData(this.GL.ARRAY_BUFFER, data, this.GL.DYNAMIC_DRAW);
        return buffer;
    }

    _createShaderProgram(vertexSource, fragmentSource, type) {
        let vertexShader = this._compileShader(vertexSource, this.GL.VERTEX_SHADER);
        let fragmentShader = this._compileShader(fragmentSource, this.GL.FRAGMENT_SHADER);
        if (!vertexShader || ! fragmentShader) {
            return false;
        }

        let program = this.GL.createProgram();

        // Bind attribute locations for this type:
        if (type === 'line') {
            this.GL.bindAttribLocation(program, this.LINE_VX_BUF, 'vertexPos');
            this.GL.bindAttribLocation(program, this.LINE_START_BUF, 'inLineStart');
            this.GL.bindAttribLocation(program, this.LINE_END_BUF, 'inLineEnd');
            this.GL.bindAttribLocation(program, this.LINE_WIDTH_BUF, 'inLineWidth');
            this.GL.bindAttribLocation(program, this.LINE_COLOR_BUF, 'inLineColor');
            this.GL.bindAttribLocation(program, this.LINE_ID_BUF, 'inLineId');
        } else if (type === 'circle') {
            this.GL.bindAttribLocation(program, this.CIRCLE_VX_BUF, 'vertexPos');
            this.GL.bindAttribLocation(program, this.CIRCLE_CENTER_BUF, 'inCircleCenter');
            this.GL.bindAttribLocation(program, this.CIRCLE_RADIUS_BUF, 'inCircleRadius');
            this.GL.bindAttribLocation(program, this.CIRCLE_FILL_COLOR_BUF, 'inCircleFillColor');
            this.GL.bindAttribLocation(program, this.CIRCLE_STROKE_COLOR_BUF, 'inCircleStrokeColor');
            this.GL.bindAttribLocation(program, this.CIRCLE_STROKE_WIDTH_BUF, 'inCircleStrokeWidth');
            this.GL.bindAttribLocation(program, this.CIRCLE_OPACITY_BUF, 'inCircleOpacity');
            this.GL.bindAttribLocation(program, this.CIRCLE_ID_BUF, 'inCircleId');
        }

        this.GL.attachShader(program, vertexShader);
        this.GL.attachShader(program, fragmentShader);
        this.GL.linkProgram(program);

        if (!this.GL.getProgramParameter(program, this.GL.LINK_STATUS)) {
            this.error = "Could not link shaders: " + this.GL.getProgramInfoLog(program);
            console.error(this.error);
            return false;
        }
        return program;
    }


    _compileShader(shaderSource, shaderType) {
        let shader = this.GL.createShader(shaderType);
        this.GL.shaderSource(shader, shaderSource);
        this.GL.compileShader(shader);

        if (!this.GL.getShaderParameter(shader, this.GL.COMPILE_STATUS)) {
            this.error = "Could not compile shader: " + this.GL.getShaderInfoLog(shader);
            console.error(this.error);
            return null;
        }
        return shader;
    }


    _initUniforms() {
        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
        this.GL.viewport(0, 0, this.canvas.width, this.canvas.height);

        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
        this.GL.viewport(0, 0, this.canvas.width, this.canvas.height);

        this.refreshView();
    }


    resize(width, height) {
        this.pxRatio = window.devicePixelRatio;
        const renderWidth = width * this.pxRatio;
        const renderHeight = height * this.pxRatio;

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;

        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;

        this.projectionMatrix = new Float32Array([
            2 / renderWidth, 0, 0,
            0, -2 / renderHeight, 0,
           -1, 1, 1
        ]);

        this._setShadowAttachmentSizes(this.canvas.width, this.canvas.height);
        this._initUniforms();
    }

    refreshView() {
        this.viewMatrix = mat3.create();
        mat3.translate(this.viewMatrix, this.viewMatrix, [
            -this.viewCenter.x + this.canvas.width / 2,
            -this.viewCenter.y + this.canvas.height / 2,
        ]);
        mat3.scale(this.viewMatrix, this.viewMatrix, [this.viewScale, this.viewScale]);
        

        for (const program of [this.shadowLineProgram, this.lineProgram, this.shadowCircleProgram, this.circleProgram]) {
            this.GL.useProgram(program);
            let projLoc = this.GL.getUniformLocation(program, 'projM');
            this.GL.uniformMatrix3fv(projLoc, false, this.projectionMatrix);

            let viewLoc = this.GL.getUniformLocation(program, 'viewM');
            this.GL.uniformMatrix3fv(viewLoc, false, this.viewMatrix);
        }
    }

    setView(viewScale, viewCenter) {
        this.viewScale = viewScale ?? this.viewScale;
        this.viewCenter = viewCenter ?? this.viewCenter;
        this.refreshView();
    }

    addLine(startX, startY, endX, endY, width, color, opacity, obj) {
        const [colorR, colorG, colorB] = color;
        
        const internalId = ++this.maxId;

        this.lineStartArray[2*this.numLines+0] = startX;
        this.lineStartArray[2*this.numLines+1] = startY;
        this.lineEndArray[2*this.numLines+0] = endX;
        this.lineEndArray[2*this.numLines+1] = endY;
        this.lineWidthArray[this.numLines] = width;
        this.lineColorArray[4*this.numLines+0] = colorR;
        this.lineColorArray[4*this.numLines+1] = colorG;
        this.lineColorArray[4*this.numLines+2] = colorB;
        this.lineColorArray[4*this.numLines+3] = opacity;
        this.lineIdArray[4*this.numLines+0] = ((internalId >>  0) & 0xFF) / 0xFF;
        this.lineIdArray[4*this.numLines+1] = ((internalId >>  8) & 0xFF) / 0xFF;
        this.lineIdArray[4*this.numLines+2] = ((internalId >> 16) & 0xFF) / 0xFF;
        this.lineIdArray[4*this.numLines+3] = ((internalId >> 24) & 0xFF) / 0xFF;
        this.objMap.set(internalId, obj);
        this.numLines++;
    }

    addCircle(centerX, centerY, radius, fillColor, strokeColor, strokeWidth, opacity, obj) {
        const [fillColorR, fillColorG, fillColorB] = fillColor;
        const [strokeColorR, strokeColorG, strokeColorB] = strokeColor;

        const internalId = ++this.maxId;

        this.circleCenterArray[2*this.numCircles+0] = centerX;
        this.circleCenterArray[2*this.numCircles+1] = centerY;
        this.circleRadiusArray[this.numCircles] = radius;
        this.circleFillColorArray[3*this.numCircles+0] = fillColorR;
        this.circleFillColorArray[3*this.numCircles+1] = fillColorG;
        this.circleFillColorArray[3*this.numCircles+2] = fillColorB;
        this.circleStrokeColorArray[3*this.numCircles+0] = strokeColorR;
        this.circleStrokeColorArray[3*this.numCircles+1] = strokeColorG;
        this.circleStrokeColorArray[3*this.numCircles+2] = strokeColorB;
        this.circleStrokeWidthArray[this.numCircles] = strokeWidth;
        this.circleOpacityArray[this.numCircles] = opacity;
        this.circleIdArray[4*this.numCircles+0] = ((internalId >>  0) & 0xFF) / 0xFF;
        this.circleIdArray[4*this.numCircles+1] = ((internalId >>  8) & 0xFF) / 0xFF;
        this.circleIdArray[4*this.numCircles+2] = ((internalId >> 16) & 0xFF) / 0xFF;
        this.circleIdArray[4*this.numCircles+3] = ((internalId >> 24) & 0xFF) / 0xFF;
        this.objMap.set(internalId, obj);
        this.numCircles++;
    }

    draw(keepOld) {
        keepOld = keepOld == null ? false : keepOld;

        let t = (Date.now() - this._startTime) / 1000;
        for (const program of [this.shadowLineProgram, this.lineProgram, this.shadowCircleProgram, this.circleProgram]) {
            this.GL.useProgram(program);
            let loc = this.GL.getUniformLocation(program, 'time');
            this.GL.uniform1f(loc, t);
        }

        // Clear:
        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
        this.GL.clear(this.GL.COLOR_BUFFER_BIT);

        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
        this.GL.clear(this.GL.COLOR_BUFFER_BIT);

        if (this.numLines > 0) {
            this._updateLineBuffers();

            // Draw shadow lines
            // TODO: only render 1 pixel, not whole canvas. https://webgl2fundamentals.org/webgl/lessons/webgl-picking.html
            // this.GL.blendFunc(this.GL.ONE, this.GL.ONE_MINUS_SRC_ALPHA);  // basic blend mode
            this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
            this.GL.blendFunc(this.GL.ONE, this.GL.ZERO);  // no blending
            this._drawLines(this.shadowLineProgram);

            // Draw lines
            this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
            this.GL.blendFunc(this.GL.ONE, this.GL.ONE_MINUS_SRC_COLOR);  // screen blend mode
            this._drawLines(this.lineProgram);

            // Debug shadow buffer
            if (this.debug) {
                this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
                this.GL.blendFunc(this.GL.ONE, this.GL.ZERO);  // no blending
                this.GL.clear(this.GL.COLOR_BUFFER_BIT);
                this._drawLines(this.shadowLineProgram);
            }
        }

        if (this.numCircles > 0) {
            this._updateCircleBuffers();

            // Draw shadow circles
            // TODO: only render 1 pixel, not whole canvas. https://webgl2fundamentals.org/webgl/lessons/webgl-picking.html
            this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
            this.GL.blendFunc(this.GL.ONE, this.GL.ZERO);  // no blending
            this._drawCircles(this.shadowCircleProgram);

            // Draw circles
            this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
            this.GL.blendFunc(this.GL.ONE, this.GL.ONE_MINUS_SRC_ALPHA);  // basic blend mode
            this._drawCircles(this.circleProgram);

            // Debug shadow buffer
            if (this.debug) {
                this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, null);
                this.GL.blendFunc(this.GL.ONE, this.GL.ZERO);  // no blending
                this._drawCircles(this.shadowCircleProgram);
            }
        }

        if (!keepOld) {
            // Don't keep old elements for next draw call
            this.numLines = 0;
            this.numCircles = 0;
            this.maxId = 0;
        }
    }

    getObjAtPixel(px, py) {
        py = this.canvas.height - py;
        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
        const data = new Uint8Array(4);
        this.GL.readPixels(px, py, 1, 1, this.GL.RGBA, this.GL.UNSIGNED_BYTE, data);
        const internalId = data[0] + (data[1] << 8) + (data[2] << 16) + (data[3] << 24);
        const obj = this.objMap.get(internalId);

        return obj;
    }

    pointToPixel(x, y) {
        const out = mat3.create();
        vec2.transformMat3(out, vec2.fromValues(x, y), this.viewMatrix);
        return {
            px: out[0],
            py: out[1],
        };
    }

    _updateLineBuffers() {
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineStartBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.lineStartArray, 0, this.numLines * 2);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineEndBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.lineEndArray , 0, this.numLines * 2);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineWidthBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.lineWidthArray , 0, this.numLines * 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineColorBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.lineColorArray , 0, this.numLines * 4);
        
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineIdBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.lineIdArray , 0, this.numLines * 4);
    }

    _updateCircleBuffers() {
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleCenterBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleCenterArray, 0, this.numCircles * 2);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleRadiusBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleRadiusArray, 0, this.numCircles * 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleFillColorBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleFillColorArray, 0, this.numCircles * 3);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleStrokeColorBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleStrokeColorArray, 0, this.numCircles * 3);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleStrokeWidthBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleStrokeWidthArray, 0, this.numCircles * 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleOpacityBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleOpacityArray, 0, this.numCircles * 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleIdBuffer);
        this.GL.bufferSubData(this.GL.ARRAY_BUFFER, 0, this.circleIdArray , 0, this.numCircles * 4);
    }

    _drawLines(program) {
        // Use line drawing shaders:
        this.GL.useProgram(program);

        this.GL.enableVertexAttribArray(this.LINE_VX_BUF);
        this.GL.enableVertexAttribArray(this.LINE_START_BUF);
        this.GL.enableVertexAttribArray(this.LINE_END_BUF);
        this.GL.enableVertexAttribArray(this.LINE_WIDTH_BUF);
        this.GL.enableVertexAttribArray(this.LINE_COLOR_BUF);
        this.GL.enableVertexAttribArray(this.LINE_ID_BUF);

        // Bind all line vertex buffers:
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineVertexBuffer);
        this.GL.vertexAttribPointer(this.LINE_VX_BUF, 3, this.GL.FLOAT, false, 0, 0);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineStartBuffer);
        this.GL.vertexAttribPointer(this.LINE_START_BUF, 2, this.GL.FLOAT, false, 8, 0);
        this.GL.vertexAttribDivisor(this.LINE_START_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineEndBuffer);
        this.GL.vertexAttribPointer(this.LINE_END_BUF, 2, this.GL.FLOAT, false, 8, 0);
        this.GL.vertexAttribDivisor(this.LINE_END_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineWidthBuffer);
        this.GL.vertexAttribPointer(this.LINE_WIDTH_BUF, 1, this.GL.FLOAT, false, 4, 0);
        this.GL.vertexAttribDivisor(this.LINE_WIDTH_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineColorBuffer);
        this.GL.vertexAttribPointer(this.LINE_COLOR_BUF, 4, this.GL.FLOAT, false, 16, 0);
        this.GL.vertexAttribDivisor(this.LINE_COLOR_BUF, 1);
    
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.lineIdBuffer);
        this.GL.vertexAttribPointer(this.LINE_ID_BUF, 4, this.GL.FLOAT, false, 16, 0);
        this.GL.vertexAttribDivisor(this.LINE_ID_BUF, 1);

        // Draw all line instances:
        this.GL.drawArraysInstanced(this.GL.TRIANGLE_STRIP, 0, 4, this.numLines);
    }


    _drawCircles(program) {
        // Use circle drawing shaders:
        this.GL.useProgram(program);

        this.GL.enableVertexAttribArray(this.CIRCLE_VX_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_CENTER_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_RADIUS_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_FILL_COLOR_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_STROKE_COLOR_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_STROKE_WIDTH_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_OPACITY_BUF);
        this.GL.enableVertexAttribArray(this.CIRCLE_ID_BUF);

        // Bind all circle vertex buffers:
        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleVertexBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_VX_BUF, 3, this.GL.FLOAT, false, 0, 0);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleCenterBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_CENTER_BUF, 2, this.GL.FLOAT, false, 8, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_CENTER_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleRadiusBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_RADIUS_BUF, 1, this.GL.FLOAT, false, 4, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_RADIUS_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleFillColorBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_FILL_COLOR_BUF, 3, this.GL.FLOAT, false, 12, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_FILL_COLOR_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleStrokeColorBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_STROKE_COLOR_BUF, 3, this.GL.FLOAT, false, 12, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_STROKE_COLOR_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleStrokeWidthBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_STROKE_WIDTH_BUF, 1, this.GL.FLOAT, false, 4, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_STROKE_WIDTH_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleOpacityBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_OPACITY_BUF, 1, this.GL.FLOAT, false, 4, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_OPACITY_BUF, 1);

        this.GL.bindBuffer(this.GL.ARRAY_BUFFER, this.circleIdBuffer);
        this.GL.vertexAttribPointer(this.CIRCLE_ID_BUF, 4, this.GL.FLOAT, false, 16, 0);
        this.GL.vertexAttribDivisor(this.CIRCLE_ID_BUF, 1);

        // Draw all circle instances:
        this.GL.drawArraysInstanced(this.GL.TRIANGLE_STRIP, 0, 4, this.numCircles);
    }


    _initLineShaders(shadow) {
        const fragSource = `#version 300 es
            #define SHADOW ${shadow? 1 : 0}
            #define PI 3.1415926535897932
            precision highp float;
            
            in vec2 uv;
            in vec4 color;
            in vec2 screenDrawingSize;

            out vec4 fragmentColor;

            uniform float time;

            void main(void) {
            #if SHADOW
                fragmentColor = color;
            #else
                float e = 1.5 / screenDrawingSize.y;
                fragmentColor = color;
                fragmentColor *= smoothstep(0.5, 0.5-e, abs(uv.y));  // smooth edges

                // animated dashed line
                // float x = uv.x + 0.5;
                // float p = sin(2.*PI*8.*x*(screenDrawingSize.x/100.) + 20.*time);
                // p = (1.+p)/2.;  // ranges 0 to 1
                // if (p > 0.7) discard;                
            #endif
            }
        `;

        const vertexSource = `#version 300 es
            #define SHADOW ${shadow? 1 : 0}
            precision highp float;

            layout(location = 0) in vec3 vertexPos;
            layout(location = 1) in vec2 inLineStart;
            layout(location = 2) in vec2 inLineEnd;
            layout(location = 3) in float inLineWidth;
            layout(location = 4) in vec4 inLineColor;
            layout(location = 5) in vec4 inLineId;

            out vec2 uv;
            out vec4 color;
            out vec2 screenDrawingSize;

            uniform mat3 projM;
            uniform mat3 viewM;

            void main(void) {
                vec2 lineStart = inLineStart;
                vec2 lineEnd = inLineEnd;

                vec2 delta = lineStart - lineEnd;
                vec2 centerPos = 0.5 * (lineStart + lineEnd);
                float lineLength = length(delta);
                float phi = atan(delta.y/delta.x);

            #if SHADOW
                color = inLineId;
                float lineWidth = inLineWidth + 4.;
            #else
                float viewScale = viewM[0][0];

                uv = vertexPos.xy;
                color = inLineColor;
                float lineWidth = inLineWidth;
                lineWidth /= pow(viewM[0][0], 0.5);

                screenDrawingSize = viewScale * vec2(lineLength, lineWidth);

                if (color.a > 0.) {
                    float minDist = 20.;
                    float maxDist = 200.;

                    float dist = max(0., lineLength - minDist);

                    float minOpacity = 0.15;
                    float maxOpacity = 0.9;
                    float distFactor = pow(max(0., maxDist - dist) / maxDist, 2.);
                    float opacityFactor = minOpacity + (maxOpacity - minOpacity) * distFactor;

                    color.a *= opacityFactor;
                } else {  // negative alpha means force this alpha and ignore shader effects (by own convention).
                    color.a = -color.a;
                }

                color.rgb *= color.a;
            #endif

                mat3 scale = mat3(
                    lineLength, 0, 0,
                    0, lineWidth, 0,
                    0, 0, 1
                );

                mat3 rotate = mat3(
                    cos(phi), sin(phi), 0,
                    -sin(phi), cos(phi), 0,
                    0, 0, 1
                );

                mat3 translate = mat3(
                    1, 0, 0,
                    0, 1, 0,
                    centerPos.x, centerPos.y, 1
                );

                gl_Position = vec4(projM * viewM * translate * rotate * scale * vertexPos, 1.0);
            }
        `;

        return this._createShaderProgram(vertexSource, fragSource, 'line');
    }

    _initCircleShaders(shadow) {
        const fragSource = `#version 300 es
            #define SHADOW ${shadow? 1 : 0}
            precision highp float;

            in vec3 fillColor;
            in vec3 strokeColor;
            in float strokeWidth;
            in float opacity;

            in vec2 uv;
            in float circleRadius;
            in float screenCircleRadius;
            in float screenDrawingSize;

            out vec4 fragmentColor;

            vec4 paintOver(vec4 bg, vec4 fg) {
                // expects pre-multiplied colors and outputs pre-multiplied color
                return vec4(
                    bg.rgb * (1. - fg.a) + fg.rgb,
                    bg.a * (1. - fg.a) + fg.a
                );
            }

            void main(void) {
                float r = length(uv);
            #if SHADOW
                if (r > 1.) discard;
                fragmentColor = vec4(fillColor, opacity);
            #else
                float shadowWidth = 5.;
                float shadowOpacity = 0.3 * smoothstep(0., 50., screenCircleRadius);

                float _strokeWidth = strokeWidth / circleRadius;
                float _shadowWidth = shadowWidth / screenDrawingSize;
                //float shadowWidth = 0.01;

                float e = 1.5 / screenDrawingSize;

                fragmentColor = vec4(0);
                // fragmentColor = vec4(1);  // for debugging

                if (shadowWidth > 0.) {
                    vec4 shadowColor = vec4(0, 0, 0, 1);
                    vec4 shadow = shadowColor * shadowOpacity * pow(smoothstep(1., 1. - _shadowWidth, r), 2.);
                    fragmentColor = paintOver(fragmentColor, shadow);
                }

                if (strokeWidth > 0.) {
                    vec4 stroke = vec4(strokeColor, 1);
                    stroke *= smoothstep(1., 1.-e, r + _shadowWidth);
                    fragmentColor = paintOver(fragmentColor, stroke);
                }

                vec4 fill = vec4(fillColor, 1);
                fill *= smoothstep(1., 1.-e, r + _shadowWidth + _strokeWidth);
                
                fragmentColor = paintOver(fragmentColor, fill);

                // vec4 reflectionColor = vec4(1, 1, 1, 1);
                // float reflectionOpacity = 0.2;
                // vec4 reflection = reflectionColor * reflectionOpacity * smoothstep(1., 1.-e, length(uv + vec2(0.2, 0.2)) + _shadowWidth);
                // reflection *= fill.a;
                // fragmentColor = paintOver(fragmentColor, reflection);

                fragmentColor *= opacity;
            #endif
            }
        `;

        const vertexSource = `#version 300 es
            #define SHADOW ${shadow? 1 : 0}
            precision highp float;
            layout(location = 0) in vec3 vertexPos;
            layout(location = 1) in vec2 inCircleCenter;
            layout(location = 2) in float inCircleRadius;
            layout(location = 3) in vec3 inCircleFillColor;
            layout(location = 4) in vec3 inCircleStrokeColor;
            layout(location = 5) in float inCircleStrokeWidth;
            layout(location = 6) in float inCircleOpacity;
            layout(location = 7) in vec4 inCircleId;

            out vec3 fillColor;
            out vec3 strokeColor;
            out float strokeWidth;
            out float opacity;

            out vec2 uv;
            out float circleRadius;
            out float screenCircleRadius;
            out float screenDrawingSize;

            uniform mat3 projM;
            uniform mat3 viewM;

            void main(void) {
                float shadowWidth = 5.;
                float MULT = 1.;

            #if SHADOW
                fillColor = inCircleId.rgb;
                opacity = inCircleId.a;
            #else
                fillColor = inCircleFillColor;
                strokeColor = inCircleStrokeColor;
                strokeWidth = inCircleStrokeWidth;
                opacity = inCircleOpacity;
            #endif
                float viewScale = viewM[0][0];

                uv = vertexPos.xy;

                vec2 pos = inCircleCenter;
                float radius = inCircleRadius * MULT;
                
                // balanced geometeric-semantic zoom
                float zoomBalance = pow(viewScale, 0.3);
                radius /= zoomBalance;
                
                float size = radius + shadowWidth / viewScale;

                circleRadius = inCircleRadius * MULT;
                screenCircleRadius = viewScale * radius;
                screenDrawingSize = viewScale * size;

                mat3 transform = mat3(
                    size, 0, 0,
                    0, size, 0,
                    pos.x, pos.y, 1
                );
                
                gl_Position = vec4(projM * viewM * transform * vertexPos, 1.0);
            }
        `;

        return this._createShaderProgram(vertexSource, fragSource, 'circle');
    }

    _setShadowAttachmentSizes(width, height) {
        this.GL.bindTexture(this.GL.TEXTURE_2D, this.shadowTargetTexture);
        // define size and format of level 0
        const level = 0;
        const internalFormat = this.GL.RGBA;
        const border = 0;
        const format = this.GL.RGBA;
        const type = this.GL.UNSIGNED_BYTE;
        const data = null;
        this.GL.texImage2D(this.GL.TEXTURE_2D, level, internalFormat,
                        width, height, border,
                        format, type, data);
    }

    _initShadow() {
        // Create a texture to render to
        this.shadowTargetTexture = this.GL.createTexture();
        this.GL.bindTexture(this.GL.TEXTURE_2D, this.shadowTargetTexture);
        this.GL.texParameteri(this.GL.TEXTURE_2D, this.GL.TEXTURE_MIN_FILTER, this.GL.LINEAR);
        this.GL.texParameteri(this.GL.TEXTURE_2D, this.GL.TEXTURE_WRAP_S, this.GL.CLAMP_TO_EDGE);
        this.GL.texParameteri(this.GL.TEXTURE_2D, this.GL.TEXTURE_WRAP_T, this.GL.CLAMP_TO_EDGE);
        
        // Create and bind the framebuffer
        this.shadowFrameBuffer = this.GL.createFramebuffer();
        this.GL.bindFramebuffer(this.GL.FRAMEBUFFER, this.shadowFrameBuffer);
        
        // attach the texture as the first color attachment
        const attachmentPoint = this.GL.COLOR_ATTACHMENT0;
        const level = 0;
        this.GL.framebufferTexture2D(this.GL.FRAMEBUFFER, attachmentPoint, this.GL.TEXTURE_2D, this.shadowTargetTexture, level);
        
        // make a depth buffer and the same size as the targetTexture
        this.GL.framebufferRenderbuffer(this.GL.FRAMEBUFFER, this.GL.DEPTH_ATTACHMENT, this.GL.RENDERBUFFER, this.shadowDepthBuffer);

        this._setShadowAttachmentSizes(this.canvas.width, this.canvas.height);
    }
}