import { sort } from 'd3-array';
import { loadHtml } from "../xhr.js";
import { Card } from "./base.js";
// import { MAX_CONNECTION_CARDS } from "./config.js";
import { humanizeFollowersCount } from "../utils.js";
import cardHtmlUrl from 'url:../../templates/cards/artist.html';

const MAX_CARD_CONNECTIONS = 20;

export class ArtistCard extends Card {
    constructor(onArtistShareClick, onArtistVisitClick, onConnectedArtistClick, onConnectedArtistMouseover, onConnectedArtistMouseout) {
        super();
        // this.onArtistExpandClick = onArtistExpandClick;
        this.onArtistShareClick = onArtistShareClick;
        this.onArtistVisitClick = onArtistVisitClick;
        this.onConnectedArtistClick = onConnectedArtistClick;
        this.onConnectedArtistMouseover = onConnectedArtistMouseover;
        this.onConnectedArtistMouseout = onConnectedArtistMouseout;
    }

    setImage(div, artist) {
        var img = div.select('#artist-image');

        img.attr('src', null)
            .style('opacity', 0);

        const imageUrl = artist.imageUrlLarge;

        if (imageUrl) {
            img.node().onload = function() {
                img.style('visibility', 'visible');
            }
            img.attr('src', imageUrl);
        } else {
            img.style('visibility', 'hidden').attr('src', null);
        }

        if (img.node().complete) {
            img.style('opacity', 1);
        } else {
            img.on('load', () => {
                img.transition().duration(200)
                    .style('opacity', 1);
            });
        }
    }

    setBasicInfo(div, artist) {
        div.select('.name').text(artist.name);

        if (artist.followers > 0)  // followers=0 is treated as null data.
            div.select('.followers').text(`${humanizeFollowersCount(artist.followers)} followers`);
        else
            div.select('.followers').text('');
        
        div.select('#artist-spotify-link').on("click", (e) => {
            const artistUrl = "https://open.spotify.com/artist/" + artist.id;
            window.open(artistUrl, '_blank').focus();
        });
    }

    setConnections(div, artist, neighborArtists, moreConnections) {
        div.select('.artist-connections').style('visibility', 'visible');
        div.select('#connections-title-n').text(moreConnections ? `${neighborArtists.length}+` : neighborArtists.length);
        div.select('#connections-title').text(neighborArtists.length == 1 ? ' connected artist': ' connected artists');

        div.select('.artist-connections-list').node().scrollTo({ top: 0, behavior: 'smooth' });

        div.select('.artist-connections-list')
            .selectAll('div.artist-block')
            .data(neighborArtists, neighborArtist => [neighborArtist.id])  // id is a list so that it never matches. [x] == [x] should always be false.
            .join(
                enter => enter.append('div')
                    .attr('class', 'artist-block medium')
                    .attr('title', neighborArtist => neighborArtist.name)
                    .html(neighborArtist => {
                        if (neighborArtist.imageUrlSmall)  // TODO: should be smaller image
                            var imgDiv = `<img src="${neighborArtist.imageUrlSmall}"></img>`
                        else
                            var imgDiv = `<img class="placeholder"></img>`

                        return `
                            <div class="image small">${imgDiv}</div>
                            <div class="name">${neighborArtist.name}</div>
                            <!--<div class="tracks-count">totalTracks.length</div>-->
                        `
                    })
                    .on("mouseover", (event, neighborArtist) => {
                        this.onConnectedArtistMouseover && this.onConnectedArtistMouseover(artist.id, neighborArtist.id);
                    })
                    .on("mouseout", (event, neighborArtist) => {
                        this.onConnectedArtistMouseout && this.onConnectedArtistMouseout(artist.id, neighborArtist.id);
                    })
                    .on("click", (event, neighborArtist) => {
                        this.onConnectedArtistClick && this.onConnectedArtistClick(artist.id, neighborArtist.id);
                    })
                ,
                update => update
                ,
                exit => exit.remove()
            );

        if (this.onArtistShareClick) {
            div.select('#artist-share')
                .attr('disabled', null)
                .on("click", (e) => {
                    this.onArtistShareClick && this.onArtistShareClick(artist);
                    e.stopPropagation();
                });
        }

        if (this.onArtistVisitClick) {
            div.select('#artist-visit')
                .attr('disabled', null)
                .on("click", (e) => {
                    this.onArtistVisitClick && this.onArtistVisitClick(artist);
                    e.stopPropagation();
                });
        }
    }

    async init(artist, provider) {
        const div = await loadHtml(cardHtmlUrl);

        const neighborArtists = provider.getArtistNeighbors(artist.id);

        var relevantNeighborArtists = sort(neighborArtists, artist => -artist.followers);  // TODO: maybe sort by -(artist.followers * connection.totalTracks)
        var moreConnections = false;

        if (neighborArtists.length > MAX_CARD_CONNECTIONS) {
            relevantNeighborArtists = relevantNeighborArtists.slice(0, MAX_CARD_CONNECTIONS);
            moreConnections = true;
        }

        const artistIds = [artist.id].concat(relevantNeighborArtists.map(artist => artist.id));
        await provider.loadArtistsInfo(artistIds);

        this.setImage(div, artist);
        this.setBasicInfo(div, artist);
        this.setConnections(div, artist, relevantNeighborArtists, moreConnections);

        this.setDiv(div);

        return this;
    }
}
