// Inspired by (and adapted in some parts from) https://github.com/vasturiano/force-graph

import stylesheet from "../css/graphapp.css";

import { forceSimulation, forceManyBody, forceLink, forceCenter } from "d3-force";
import { BatchDrawer } from "./drawer/webgl.js";
import { LabelsDrawer } from "./drawer/labels.js";
import { Graph, Node, UndirectedLink } from "./graph/graph.js";
import { zoom, zoomTransform } from "d3-zoom";
import { drag } from "d3-drag";
import { sort, max, min } from "d3-array";
import { inertiaPanZoom } from './d3-inertia/index.js';
import { Tween, Easing, update as updateTween} from '@tweenjs/tween.js';


const COLORS = {
    black: [0, 0, 0],
    nodeFillBase: [0.384, 0.384, 0.839],  // "#6262d6"
    nodeFillHighlighted: [0.431, 0.431, 0.858],  // "#6e6edb"
    nodeFillDimmed: [0.227, 0.227, 0.411],  // "#6e6edb"
    nodeStrokeHighlighted: [0.545, 0.698, 0.917],  // "#8bb2ea"
    nodeStrokeExpanded: [0.386, 0.714, 0.964],  // "#63b7f7"
    nodeStrokeExpandedDimmed: [0.1796875, 0.3046875, 0.3984375],  // "#63b7f7"
    linkBase: [0.631, 0.654, 0.894],  // "#a1a7e4", if used with screen blend mode
    // linkHighlighted: [1., 0.578, 0.820],  // "#ff7ac8";
    linkHighlighted: [1., 0.760, 0.901],  // "#ffc2e6";
    linkHovered: [1., 0.760, 0.901],  // "#ffc2e6";
};


class Animator {
    constructor(onUpdate) {
        this.onUpdate = onUpdate;
    }

    set(property, value, delay=0, duration=100, easing=Easing.Quadratic.Out, onUpdate=null) {
        if (this["_target_" + property] != value) {
            this["_target_" + property] = value;

            const tween = this["_tween_" + property]
            if (tween) tween.stop();

            this["_tween_" + property] = new Tween(this)
                .easing(easing)
                .delay(delay)
                .to({
                    [property]: value,
                }, duration)
                .onUpdate(() => {
                    this.onUpdate && this.onUpdate();
                    onUpdate && onUpdate();
                })
                .start();
        }
    }

    get(property) {
        return this[property];
    }
}


export class GraphApp {
    constructor(container, debugContainer) {
        this.pxRatio = window.devicePixelRatio;
        this.container = container;
        this.enableZoomPanInteraction = true;
        this.enableNodeDrag = true;
        this.simulationMinAlpha = 0;
        this.warmupTicks = 0;
        this.cooldownTicks = Infinity;
        this.cooldownTime = 20000;
        this.minZoom = 0.2;
        this.maxZoom = 3.0;
        this.graph = new Graph();
        this.onNodeSelected = null;
        this.onLinkSelected = null;
        this.onUnselectAll = null;
        this.nodeWeight = node => 1;
        this.linkWeight = link => 1;
        this.getNodeSubgraph = node => new Graph();
        this.debugContainer = debugContainer;
        this.resetState();
        this.setupCanvas();
        this.setupPointerEvents();
        this.setupPanZoomInteractions();
        this.setupDragInteractions();
        this.setupSimulation();
        this.graphChanged();
        this.sizeChanged();

        // var rS = new rStats();

        const self = this;
        (this._animationCycle = function animate(time) { // IIFE
            // rS( 'frame' ).start();
            const doRedraw = self.state.needsRedraw || self.state.engineRunning;
            self.state.needsRedraw = false;

            const obj = !self.state.isPointerDragging ? self.getObjUnderPointer() : null;

            if (obj !== self.state.hoveredObj && (!obj || !self.isDimmed(obj))) {
                if (obj) obj.hovered = true;
                if (self.state.hoveredObj) self.state.hoveredObj.hovered = false;

                if (obj && obj instanceof UndirectedLink) {
                    self.onLinkMouseover(obj);
                }

                if (self.state.hoveredObj && self.state.hoveredObj instanceof UndirectedLink) {
                    self.onLinkMouseout(self.state.hoveredObj);
                }

                self.state.hoveredObj = obj;
                self.mainCanvas.classed("clickable", !!obj)
                self.state.needsRedraw = true;
            }

            if (doRedraw) {
                self.tick();
            }

            // rS( 'frame' ).end();
            // rS().update();

            self.animationFrameRequestId = requestAnimationFrame(animate);
            updateTween(time); // update canvas animation tweens
        })();
    }

    resetState(removeCard=true) {
        this.unselectAll(removeCard);
        this.graph = new Graph();
        this.state = {
            engineRunning: true,
            countTicks: 0,
            startTickTime: new Date(),
            needsRedraw: true,
            animationFrameRequestId: null,
            pointerPos: { x: 0, y: 0 },
            isPointerDragging: false,
            isPointerPressed: false,
            hoveredObj: null,
            selectedNode: null,
            selectedLink: null,
            showLabels: true,
            prevN: 0,
            prevM: 0,
            viewTween: null,
        }
    }

    resetView() {
        this.pxRatio = window.devicePixelRatio;
        this._panZoom.setCenter(0, 0);
        this._panZoom.setZoom(1);
    }

    setZoom(k, duration=0) {
        this._panZoom.setZoom(k, duration);
    }

    refreshDebug() {
        this.debugContainer.select('#debug-nodes').text(this.graph.n ?? '?');
        this.debugContainer.select('#debug-links').text(this.graph.m ?? '?');
        this.debugContainer.select('#debug-blocks').text('?');
    }

    setupCanvas() {
        this.container.classed("graph-container", true);

        this.mainCanvas = this.container.append("canvas")
            .attr("id", "main-canvas")
            .attr("width", window.innerWidth)
            .attr("height", window.innerHeight);
        
        this.loadingDivs = this.container.append("div")
            .attr("width", window.innerWidth)
            .attr("height", window.innerHeight);
        
        this.labelsCanvas = this.container.append("canvas")
            .attr("id", "labels-canvas")
            .attr("width", window.innerWidth)
            .attr("height", window.innerHeight);

        this.mainDrawer = new BatchDrawer(this.mainCanvas.node(), {
            maxLines: 100000, // used for preallocation. TODO: pass reasonable values here.
            maxCircles: 100000, // used for preallocation. TODO: pass reasonable values here.
            clearColor: { r: 0, g: 0, b: 0, a: 0 }, // Color to clear screen with
        });

        this.labelsDrawer = new LabelsDrawer(this.labelsCanvas.node());
    }

    setupSimulation() {
        this.simulation = forceSimulation().stop();

        this.simulation.force("link", forceLink()
            .id(link => link.id)
            // .strength(d => (Math.sqrt(Math.min(80, d.weight || 1))) * 0.02)
            .strength(link => link.strength)
            .distance(20)
            .iterations(1)
        );

        this.simulation.force("charge", forceManyBody()
            .strength(-100)
        );

        this.simulation.force("center", forceCenter());
    }

    setupPointerEvents() {
        this.container.on("pointermove pointerdown", ev => {
            if (ev.type === "pointerdown") {
                this.state.isPointerPressed = true; // track click state
            }

            // detect pointer drag on canvas pan
            !this.state.isPointerDragging && ev.type === "pointermove"
            && (this.onBackgroundClick) // only bother detecting drags this way if background clicks are enabled (so they don"t trigger accidentally on canvas panning)
            && (ev.pressure > 0 || this.state.isPointerPressed) // ev.pressure always 0 on Safari, so we use the isPointerPressed tracker
            && (ev.pointerType !== "touch" || ev.movementX === undefined || [ev.movementX, ev.movementY].some(m => Math.abs(m) > 1)) // relax drag trigger sensitivity on touch events
            && (this.state.isPointerDragging = true);

            // update the pointer pos
            const offset = getOffset(this.container);
            this.state.pointerPos.x = ev.pageX - offset.left;
            this.state.pointerPos.y = ev.pageY - offset.top;

            function getOffset(el) {
                const rect = el.node().getBoundingClientRect(),
                    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
                    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
            }
        }, { passive: true });

        this.container.on("pointerup", ev => {
            this.state.isPointerPressed = false;
            if (this.state.isPointerDragging) {
                this.state.isPointerDragging = false;
                return; // don"t trigger click events after pointer drag (pan / node drag functionality)
            }

            requestAnimationFrame(() => { // trigger click events asynchronously, to allow hoveredObj to be set (on frame)
                const obj = this.state.hoveredObj;

                if (ev.button === 0) { // mouse left-click or touch
                    if (obj && obj instanceof Node) {
                        this.onNodeClick(obj);
                    } else if (obj) {
                        this.onLinkClick(obj);
                    } else {
                        this.onBackgroundClick();
                    }
                }

                if (ev.button === 2) { // mouse right-click
                    if (obj && obj instanceof Node) {
                        this.onNodeRightClick && this.onNodeRightClick(obj);
                    } else if (obj) {
                        this.onLinkRightClick && this.onLinkRightClick(obj);
                    } else {
                        this.onBackgroundRightClick && this.onBackgroundRightClick();
                    }
                }
            });
        }, { passive: true });

        this.container.on("contextmenu", ev => {
            ev.preventDefault();
            return false;
        });
    }

    _positionFromTransform(transform) {
        return {
            x: (transform.x) + ((this.width/2)*transform.k - (this.width/2)),
            y: (transform.y) + ((this.height/2)*transform.k - (this.height/2)),
            k: transform.k,
        };
    }

    _transformFromPosition(p) {
        return {
            x: -(p.x) - (this.width/2)*p.k + (this.width/2),
            y: -(p.y) - (this.height/2)*p.k + (this.height/2),
            k: p.k,
        };
    }

    setupPanZoomInteractions() {
        this._panZoom = inertiaPanZoom(this.mainCanvas, inertia => {
            const p = inertia.p; 
            this.mainDrawer.setView(p.k * this.pxRatio, {x: p.x * this.pxRatio, y: p.y * this.pxRatio});
            this.labelsDrawer.setView(p.k * this.pxRatio, {x: p.x * this.pxRatio, y: p.y * this.pxRatio});
            this.state.needsRedraw = true;
        }, p => {
            // during glide, and during zoom tween, d3Transform and _panZoom get unsynced, resync.
            const transform = this._transformFromPosition(p);
            this.mainCanvas.node().__zoom.x = transform.x;
            this.mainCanvas.node().__zoom.y = transform.y;
            this.mainCanvas.node().__zoom.k = transform.k;
        }, {
            glideTime: 500,
            zoomTime: 200,
        });

        this.d3Zoom = zoom()
            .filter(ev => {
                if (!this._panZoom.enabled) return false;
                if (ev.type == "wheel") return true;

                const obj = this.getObjUnderPointer()
                return !obj || !(obj instanceof Node);
            })
            .scaleExtent([this.minZoom, this.maxZoom])
            .wheelDelta(ev => {
                return -ev.deltaY * (ev.deltaMode === 1 ? 0.02 : ev.deltaMode ? 1 : 0.005);
            })
            .on("start", ev => {
                const position = this._positionFromTransform(ev.transform);
                this._panZoom.start(position);
            })
            .on("zoom", ev => {
                const position = this._positionFromTransform(ev.transform);

                let eventType = "unknown";
                if (window.WheelEvent && ev.sourceEvent instanceof WheelEvent) {
                    eventType = Math.abs(ev.sourceEvent.deltaY ?? 0) > 25 ? "zoom-big" : "zoom-small";
                } else if (window.MouseEvent && ev.sourceEvent instanceof MouseEvent) {
                    eventType = "pan";
                } else if (window.TouchEvent && ev.sourceEvent instanceof TouchEvent){
                    eventType = "touch";
                }

                this._panZoom.move(position, eventType);
            })
            .on("end", ev => {
                this._panZoom.end();
            });

        this.mainCanvas.call(this.d3Zoom);
        
        this.mainCanvas.on("dblclick.zoom", null);  // disable double click/tap to zoom
    }

    setupDragInteractions() {
        this.d3Drag = drag()
            .subject(() => {
                if (!this.enableNodeDrag) return null;
                
                const obj = this.getObjUnderPointer();

                if (!(obj instanceof Node)) return null;
                if (this.isDimmed(obj)) return null;

                return obj;
            })
            .on("start", ev => {
                const obj = ev.subject;
                obj.__initialDragPos = { x: obj.x, y: obj.y, fx: obj.fx, fy: obj.fy };

                // keep engine running at low intensity throughout drag
                if (!ev.active) {
                    obj.fx = obj.x; obj.fy = obj.y; // Fix points
                }

                // drag cursor
                this.mainCanvas.classed("grabbable", true);
            })
            .on("drag", ev => {
                const obj = ev.subject;

                const initPos = obj.__initialDragPos;
                const dragPos = ev;

                const k = this._panZoom.p.k;

                // Move fx/fy (and x/y) of nodes based on the scaled drag distance since the drag start
                obj.fx = obj.x = initPos.x + (dragPos.x - initPos.x) / k;
                obj.fy = obj.y = initPos.y + (dragPos.y - initPos.y) / k;

                // keep engine running at low intensity throughout drag
                this.simulation.alphaTarget(0.3)
                
                // prevent freeze while dragging
                this.resetCountdown();

                // signal drag started, here instead of on "start" because otherwise we don"t detect node clicks.
                this.state.isPointerDragging = true;

                // disable pan&zoom
                this._panZoom.enabled = false;

                obj.__dragged = true;
            })
            .on("end", ev => {
                const obj = ev.subject;

                const initPos = obj.__initialDragPos;

                // only unfix node if it was unfixed when drag started
                if (initPos.fx == null) { obj.fx = undefined; }
                if (initPos.fy == null) { obj.fy = undefined; }

                delete (obj.__initialDragPos);

                this.simulation.alphaTarget(0)  // release engine low intensity
                this.resetCountdown();  // let the engine readjust after releasing fixed nodes

                // drag cursor
                this.mainCanvas.classed("grabbable", false);

                // signal drag ended
                this.state.isPointerDragging = false;

                // enable pan&zoom
                this._panZoom.enabled = true;

                if (obj.__dragged) {
                    delete (obj.__dragged);
                }
            })
        ;

        this.mainCanvas.call(this.d3Drag);
    }

    tick() {
        const graphLayoutTick = () => {
            if (!this.state.engineRunning) return;

            if (
                ++this.state.countTicks > this.cooldownTicks ||
                (new Date()) - this.state.startTickTime > this.cooldownTime ||
                (this.simulationMinAlpha > 0 && this.simulation.alpha() < this.simulationMinAlpha)
            ) {
                this.state.engineRunning = false;
            } else {
                this.simulation.tick();
            }
        }

        const drawNodes = () => {
            let fillColor, strokeColor, strokeWidth;

            this.graphNodes.forEach(node => {
                if (this.graph.nodeIsExpanded(node.id)) {
                    node.animator.set("radius", node.baseRadius + (node.hovered ? 6 : 4));
                    fillColor = this.isDimmed(node) ? COLORS.nodeFillDimmed : COLORS.nodeFillHighlighted;
                    strokeColor = this.isDimmed(node) ? COLORS.nodeStrokeExpandedDimmed : COLORS.nodeStrokeExpanded;
                    strokeWidth = 2;
                } else if (node.loadingDiv) {
                    node.animator.set("radius", node.baseRadius + (node.hovered ? 6 : 4));
                    fillColor = this.isDimmed(node) ? COLORS.nodeFillDimmed : COLORS.nodeFillHighlighted;
                    strokeColor = COLORS.black;
                    strokeWidth = 0;
                } else if (node.selected) {
                    node.animator.set("radius", node.baseRadius + 6);
                    fillColor = COLORS.nodeFillHighlighted;
                    strokeColor = COLORS.nodeStrokeHighlighted;
                    strokeWidth = 2;
                } else if (node.highlighted) {
                    node.animator.set("radius", node.baseRadius + (node.hovered ? 2 : 0));
                    fillColor = COLORS.nodeFillHighlighted;
                    strokeColor = COLORS.nodeStrokeHighlighted;
                    strokeWidth = 1;
                } else {
                    node.animator.set("radius", node.baseRadius + (node.hovered ? 2 : 0));
                    fillColor = this.isDimmed(node) ? COLORS.nodeFillDimmed : COLORS.nodeFillBase;
                    strokeColor = COLORS.black;
                    strokeWidth = 0;
                }

                this.mainDrawer.addCircle(
                    node.x,
                    node.y,
                    node.animator.radius,
                    fillColor,
                    strokeColor,
                    strokeWidth,
                    node.animator.opacityIn,
                    node,
                );
                
                if (node.loadingDiv) {
                    const {px, py} = this.mainDrawer.pointToPixel(node.x, node.y)
                    const scale = node.animator.radius / 20 / Math.pow(this._panZoom.p.k, 0.3) * this._panZoom.p.k;
                    node.loadingDiv.style("transform", `translate(${px}px, ${py}px) scale(${scale})`);
                }
            });
        }

        const drawLinks = () => {
            let width, color, opacity;

            this.graphLinks.forEach(link => {

                if (link.selected) {
                    color = COLORS.linkHighlighted;
                    width = link.width + 2;
                    opacity = -0.9;
                    // opacity = -0.9;  // negative alpha means force this alpha (absolute value) and ignore shader effects (custom convention).
                } else if (link.highlighted) {
                    color = COLORS.linkHighlighted;
                    width = link.width + 1;
                    opacity = -0.75;
                    // opacity = -0.75;  // negative alpha means force this alpha (absolute value) and ignore shader effects (custom convention).
                } else if (this.isDimmed(link)) {
                    color = COLORS.linkBase;
                    width = link.width;
                    opacity = 0.3;
                    // opacity = 0.3;
                } else {
                    color = COLORS.linkBase;
                    width = link.width;
                    opacity = 0.9;
                    // opacity = 0.9;
                }

                if (link.hovered && !link.selected) {  // link hovered
                    color = COLORS.linkHovered;
                    width += 2;
                    opacity = -0.75;  // negative alpha means force this alpha (absolute value) and ignore shader effects (custom convention).
                }

                this.mainDrawer.addLine(
                    link.source.x,
                    link.source.y,
                    link.target.x,
                    link.target.y,
                    width, // width,
                    color, // ...color,
                    link.animator.opacityIn * opacity,
                    link,
                );
            });
        }

        const drawLabels = () => {
            if (!this.state.showLabels) return;

            // we want highlighted labels with more followers to have priority
            // TODO: it's very inefficient to sort each time!
            const nodes = sort(this.graphNodes, node => !node.highlighted, node => -this.nodeWeight(node));
        
            let opacity, mustShow, padding;

            nodes.forEach(node => {
                if (node.selected) {
                    opacity = 0.75;
                    mustShow = true;
                    padding = false;
                } else if (node.highlighted) {
                    opacity = 0.75;
                    mustShow = false;
                    padding = false;
                } else if (this.selectedNode) {  // some other node is selected
                    opacity = 0.2;
                    mustShow = false;
                    padding = true;
                } else {
                    opacity = 0.75;
                    mustShow = false;
                    padding = true;
                }

                if (node.hovered) {
                    mustShow = true;
                    padding = true;
                }

                if (this.graph.nodeIsExpanded(node.id)) {
                    mustShow = true;
                    padding = true;
                }

                if (mustShow) {
                    node.animator.set("labelOpacity", opacity, 0, 200);
                } else if (node.labelCollides ?? true) {
                    node.animator.set("labelOpacity", 0, 0, 200);
                } else {
                    node.animator.set("labelOpacity", opacity, 0, 200);
                }

                node.labelCollides = this.labelsDrawer.addLabel(
                    node.id,
                    node.name,
                    node.x,
                    node.y,
                    node.fontSize,
                    node.animator.labelOpacityIn * node.animator.labelOpacity,
                    mustShow || node.animator.labelOpacityIn > 0,
                    padding,
                )
            });
        }

        this.labelsDrawer.clear() // labelsDrawer needs explicit clearing

        graphLayoutTick();
        drawLinks();
        drawNodes();
        drawLabels();

        this.mainDrawer.draw();
    }

    nodeRadius(node, minWeight=0, maxWeight=1.5) {
        const weight = this.nodeWeight(node);
        const n = (Math.min(weight, maxWeight) - minWeight) / (maxWeight - minWeight);  // linear 0 to 1
        // const f = 1 - Math.pow(1 - n, 3);  // cubic out 0 to 1

        return 6 + 4*n;
    }
    
    fontSize(node, minWeight=0, maxWeight=1.5) {
        const weight = this.nodeWeight(node);
        const n = (Math.min(weight, maxWeight) - minWeight) / (maxWeight - minWeight);  // linear 0 to 1
        // const f = 1 - Math.pow(1 - n, 3);  // cubic out 0 to 1

        return 10 + 3*n;
    }

    linkWidth(link, maxWeight=30) {
        const weight = this.linkWeight(link);
        const n = Math.min(weight, maxWeight) / maxWeight;  // linear 0 to 1
        const f = 1 - Math.pow(1 - n, 3);  // cubic out 0 to 1

        return 3 + 3*f;
    }

    linkStrength(link) {
        const weight = this.linkWeight(link);
        var f = Math.min(50, weight ?? 1) / 50;
        return 0.2 * Math.pow(f, 0.5) + (0.02 * Math.random());
    }

    resetCountdown() {
        this.state.countTicks = 0;
        this.state.startTickTime = new Date();
        this.state.engineRunning = true;
        return this;
    }

    pauseAnimation() {
        if (this.state.animationFrameRequestId) {
            cancelAnimationFrame(this.state.animationFrameRequestId);
            this.state.animationFrameRequestId = null;
        }

        return this;
    }

    resumeAnimation() {
        if (!this.state.animationFrameRequestId) {
            this._animationCycle();
        }
        return this;
    }

    addNode(node) {
        this.graph.addNode(node);
        this.graphChanged();
    }

    addSubgraph(subgraph, center) {
        this.graph.merge(subgraph);
        const nSqrtNodes = Math.sqrt(this.graph.nodesArray.length);

        const randomPositionAroundCenter = ({ cx, cy }) => {
            var angle = 2 * Math.PI * Math.random();
            var radius = (100 + 400 * Math.random()) + 6 * nSqrtNodes;
            var x = (cx + radius * Math.sin(angle));
            var y = (cy + radius * Math.cos(angle));
            return { x: x, y: y };
        };

        subgraph.nodesArray.forEach(node => {
            if (node.x === undefined || node.y === undefined) {
                const { x, y } = randomPositionAroundCenter(center);
                node.x = x;
                node.y = y;
            }
        });
        
        this.graphChanged();
    }

    graphChanged() {
        this.graphNodes = this.graph.nodesArray;
        this.graphLinks = this.graph.linksArray;

        this.simulation
            .stop()
            .alpha(1) // re-heat the simulation
            .nodes(this.graphNodes);

        const linkForce = this.simulation.force("link");

        const minWeight = min(this.graphNodes.map(node => this.nodeWeight(node))) / 2;
        const maxWeight = max(this.graphNodes.map(node => this.nodeWeight(node)));
        this.graphNodes.forEach(node => {
            node.baseRadius = this.nodeRadius(node, minWeight, maxWeight);
            node.fontSize = this.fontSize(node, minWeight, maxWeight);

            node.animator = node.animator ?? new Animator(() => { this.state.needsRedraw = true; });
            node.animator.radius = node.animator.radius ?? 0;
            node.animator.opacityIn = node.animator.opacityIn ?? 0;
            node.animator.labelOpacityIn = node.animator.labelOpacityIn ?? 0;
            node.animator.labelOpacity = node.animator.labelOpacity ?? 0;
            node.animator.set("opacityIn", 1, 750, 500);
            node.animator.set("labelOpacityIn", 1, 750, 500);
        });

        this.graphLinks.forEach(link => {
            link.width = this.linkWidth(link);
            link.baseStrength = this.linkStrength(link);

            link.animator = link.animator ?? new Animator(() => { this.state.needsRedraw = true; });
            link.animator.opacityIn = link.animator.opacityIn ?? 0;
            link.animator.strength = link.animator.strength ?? 0;
            link.animator.set("opacityIn", 1, 750, 500);
            link.animator.set("strength", link.baseStrength, 500, 0, Easing.Cubic.In, () => linkForce.strength(link => link.animator.strength));
        });

        linkForce
            .id(node => node.id)
            .links(this.graphLinks)
            .strength(link => link.animator.strength);

        for (let i = 0; (i < this.warmupTicks) && !(this.simulationMinAlpha > 0 && this.simulation.alpha() < state.simulationMinAlpha); i++) {
            this.simulation.tick();
        } // Initial ticks before starting to render

        this.resetCountdown();

        if (this.debugContainer) {
            this.refreshDebug()
        };

        // auto zoom
        const k = this._panZoom.p.k;
        const dk = -Math.pow(Math.max(1, this.graph.n - (this.state.prevN + 10)), 0.5) / 40;

        this.setZoom(Math.min(Math.max(k + dk, this.minZoom), this.maxZoom), 1000);

        this.state.prevN = this.graph.n;
        this.state.prevM = this.graph.m;
    }

    getObjUnderPointer() {
        const pxScale = window.devicePixelRatio;
        const obj = this.mainDrawer.getObjAtPixel(this.state.pointerPos.x * pxScale, this.state.pointerPos.y * pxScale);

        return obj;
    };

    sizeChanged() {
        this.width = this.container.node().getBoundingClientRect().width;
        this.height = this.container.node().getBoundingClientRect().height;

        this.mainDrawer.resize(this.width, this.height);
        this.labelsDrawer.resize(this.width, this.height);
        
        this.pxRatio = window.devicePixelRatio;
        this.renderWidth = this.width * this.pxRatio;
        this.renderHeight = this.height * this.pxRatio;

        this.state.needsRedraw = true;
    }

    unselectAll(removeCard) {
        this.unselectNode();
        this.unselectLink();

        this.onUnselectAll && this.onUnselectAll(removeCard);
    }

    unselectNode() {
        const node = this.selectedNode;
        if(!node) {
            return
        }

        node.selected = false;

        this.graph.nodeNeighbors(node.id).forEach(nodeNeighbor => {
            nodeNeighbor.highlighted = false;
            const link = this.graph.getLink(node.id, nodeNeighbor.id);
            link.highlighted = false;
        });

        this.selectedNode = null;
    }

    unselectLink() {
        const link = this.selectedLink;
        if(!link) {
            return
        }

        link.selected = false;
        link.source.selected = false;
        link.target.selected = false;
        link.source.highlighted = false;
        link.target.highlighted = false;

        this.selectedLink = null;
    }

    selectNode(node) {
        if (this.selectedNode === node) {
            return;
        }

        this.unselectAll();

        node.selected = true;

        this.graph.nodeNeighbors(node.id).forEach(nodeNeighbor => {
            nodeNeighbor.highlighted = true;
            const link = this.graph.getLink(node.id, nodeNeighbor.id);
            link.highlighted = true;
        });

        this.selectedNode = node;
        this.onNodeSelected && this.onNodeSelected(node);
    }

    selectLink(link) {
        if (this.selectedLink === link) {
            return;
        }

        this.unselectAll();

        link.selected = true;
        link.source.selected = true;
        link.target.selected = true;
        link.source.highlighted = true;
        link.target.highlighted = true;

        this.selectedLink = link;
        this.onLinkSelected && this.onLinkSelected(link);
    }

    async expandNode(node) {
        if (this.graph.nodeIsExpanded(node.id)) {
            return;
        }

        this.setNodeLoading(node);
        const nodeSubgraph = await this.getNodeSubgraph(node);
        this.addSubgraph(nodeSubgraph, { cx: node.x ?? 0, cy: node.y ?? 0 });
        this.graph.markNodeExpanded(node.id);
        this.removeNodeLoading(node);
    }

    onNodeClick(node) {
        console.log("Node click", node)
        if (node.loadingDiv) {
            // do nothing
        } else if (this.selectedNode && this.selectedNode !== node) {
            const link = this.graph.getLink(this.selectedNode.id, node.id);
            this.selectLink(link);
        } else if (!this.graph.nodeIsExpanded(node.id)) {
            this.unselectAll();
            this.expandNode(node).then(() => {});
        } else {
            this.selectNode(node);
        }
    }

    onLinkClick(link) {
        this.selectLink(link);
    }

    onLinkMouseover(link) {
        if (this.inSelection()) return;

        link.source.highlighted = true;
        link.target.highlighted = true;
    }

    onLinkMouseout(link) {
        if (this.inSelection()) return;

        link.source.highlighted = false;
        link.target.highlighted = false;
    }

    onBackgroundClick() {
        this.unselectAll();
    }

    inSelection() {
        return this.selectedNode || this.selectedLink;
    }

    isDimmed(obj) {
        return this.inSelection() && !obj.selected && !obj.highlighted;
    }

    setLabelsVisible(visible) {
        this.state.showLabels = visible;
        this.state.needsRedraw = true;
    }

    setNodeLoading(node) {
        const loadingDiv = this.loadingDivs.append("div");
        const {px, py} = this.mainDrawer.pointToPixel(node.x ?? 0, node.y ?? 0);
        const scale = (node.animator?.radius ?? 20) / 20 / Math.pow(this._panZoom.p.k, 0.3) * this._panZoom.p.k;

        loadingDiv.attr("class", "loading-dual-ring")
            .style("position", "absolute")
            .style("top", -(loadingDiv.node().getBoundingClientRect().height / 2) + "px")
            .style("left", -(loadingDiv.node().getBoundingClientRect().width / 2) + "px")
            .style("transform", `translate(${px}px, ${py}px) scale(${scale})`);
        
        node.loadingDiv = loadingDiv;
    }

    removeNodeLoading(node) {
        node.loadingDiv?.remove();
        node.loadingDiv = null;
    }
}
