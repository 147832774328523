import { loadHtml } from "../xhr.js";
import { Card } from "./base.js"
import cardHtmlUrl from "url:../../templates/cards/error.html";

export class ErrorCard extends Card {
    static display = "flex";

    constructor() {
        super();
    }

    async init(errorMsg) {
        this.div = await loadHtml(cardHtmlUrl);

        if (errorMsg) {
            this.div.select("#error-msg").html(errorMsg);
        }

        return this;
    }
}
