import { select } from "d3-selection";
import { sort } from 'd3-array';
import { loadHtml } from '../xhr.js';
import { Card } from './base.js'
import cardHtmlUrl from 'url:../../templates/cards/connection.html';

const MAX_CARD_TRACKS = 5;

export class ConnectionCard extends Card {
    constructor(onArtistClick) {
        super();
        this.onArtistClick = onArtistClick;
    }

    setArtistBlock(div, artist) {
        div.on('click', () => {
            this.onArtistClick && this.onArtistClick(artist.id);
        });

        if (artist.imageUrlSmall)
            var imgDiv = `<img src="${artist.imageUrlSmall}"></img>`
        else
            var imgDiv = `<img class="placeholder"></img>`

        div.select('.image').html(imgDiv);
        div.select('.name').text(artist.name);
        div.attr('title', artist.name);
    }

    setTracks(div, connection) {
        const audioManager = this.audioManager;
        let tracks = connection.tracks.filter(track => track !== undefined);
        tracks = sort(tracks, track => track.name);

        div.select('.tracks-list')
            .html('')  // clear out contents (e.g get rid of '+ more' item)
            .selectAll('a')
            .data(tracks, track => track.id)
            .join('div')
                .attr('class', 'track')
                .each(function(track) {
                    const div = select(this);

                    const aMain = div.append("a").attr("class", "main");
                    const aPlay = div.append("a").attr("class", "play");

                    aMain.append("div")
                        .classed("song", true);

                    aMain.append("div")
                        .text(track.name)
                        .classed("name", true);
                    
                    aMain.append("div")
                        .text(track.releaseYear)
                        .classed("year", true);

                    aMain.attr('target', '_blank')
                        .attr('href', track => 'https://open.spotify.com/track/' + track.id);

                    const playIcon = aPlay.append("div").classed("play", true);
                    
                    aPlay.classed("disabled", !track.previewUrl);

                    if (track.previewUrl) {
                        audioManager.bindButton(aPlay, track.previewUrl,
                            () => {
                                playIcon.classed("play", false);
                                playIcon.classed("pause", true);
                            },
                            () => {
                                playIcon.classed("pause", false);
                                playIcon.classed("play", true);
                            }
                        );
                    }
                });
        
        if (connection.numTracks > tracks.length) {
            div.select('.tracks-list')
                .append('div')
                    .attr('class', 'track more')
                    .html(track => `
                        <div>+ more</div>
                    `);
        }
    }

    async init(connection, provider, audioManager) {
        const div = await loadHtml(cardHtmlUrl);
        this.audioManager = audioManager;

        const trackIds = connection.trackIds;
        var relevantTrackIds = trackIds;

        // in most cases we have no way of sorting the tracks to make a good slice, we only have the ids.
        // For this reason, the subset of tracks should be curated by the backend already.
        if (trackIds.length > MAX_CARD_TRACKS) {
            relevantTrackIds = relevantTrackIds.slice(0, MAX_CARD_TRACKS);
        }

        await Promise.all([
            provider.loadArtistsInfo([connection.artistA.id, connection.artistB.id]),
            provider.loadTracksInfo(relevantTrackIds),
        ]);

        this.setArtistBlock(div.select('.header .artist-block.left'), connection.artistA);
        this.setArtistBlock(div.select('.header .artist-block.right'), connection.artistB);
        this.setTracks(div, connection);

        this.setDiv(div);

        return this;
    }

    onClose() {
        this.audioManager.stopCurrentAudio();
    }
}
