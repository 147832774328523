import RBush from "rbush";


function wrapText(ctx, text, x, y, maxWidth, lineHeight, stroke=false) {
    var finalLines = [];

    var words = text.split(' ');
    var line = '';

    for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var testWidth = ctx.measureText(testLine).width;
        if (testWidth > maxWidth && n > 0) {
            finalLines.push(line);
            line = words[n] + ' ';
        } else {
            line = testLine;
        }
    }

    finalLines.push(line);

    y = 0;
    for (line of finalLines) {
        if (stroke) ctx.strokeText(line, x, y);
        ctx.fillText(line, x, y);
        y += lineHeight;
    }
}


function textBbox(ctx, text, x, y, maxWidth, lineHeight) {
    // TODO: combine with .wrapText function, and store bbox in node to reuse? It does similar calculations.
    var finalWidths = [];

    var words = text.split(' ');

    if (words.length < 1) {
        throw "textBbbox requires text."
    }

    var line = '';

    for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + ' ';
        var width = ctx.measureText(line).width;
        var newWidth = ctx.measureText(testLine).width;
        if (newWidth > maxWidth && n > 0) {
            finalWidths.push(width);
            line = words[n] + ' ';
        } else {
            line = testLine;
        }
    }

    width = ctx.measureText(line).width;
    finalWidths.push(width);
    
    const nLines = finalWidths.length;

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    y = 0;
    for (var width of finalWidths) {
        let _x = x - width / 2;
        let _y = y - lineHeight / 2;

        minX = Math.min(minX, _x);
        minY = Math.min(minY, _y);
        maxX = Math.max(maxX, _x + width);
        maxY = Math.max(maxY, _y + lineHeight);
        y += lineHeight;
    }

    return { minX, minY, maxX, maxY, width: maxX - minX, height: maxY - minY };
}

function padBbox(bbox, paddingX=4, paddingY=4, inplace=false) {
    if (!inplace) {
        bbox = structuredClone(bbox);
    }

    bbox.minX -= paddingX;
    bbox.minY -= paddingY;
    bbox.maxX += paddingX;
    bbox.maxY += paddingY;
    bbox.width += 2*paddingX;
    bbox.height += 2*paddingY;

    return bbox;
}


function translateBbox(bbox, dx=4, dy=4, inplace=false) {
    if (!inplace) {
        bbox = structuredClone(bbox);
    }

    bbox.minX += dx;
    bbox.minY += dx;
    bbox.maxX += dy;
    bbox.maxY += dy;

    return bbox
}


const labelsCache = new Map();

export class LabelsDrawer {
    constructor(canvas) {
        this.canvas = canvas;
        this.ctx = canvas.getContext('2d');

        this.viewScale = 1;
        this.viewCenter = {x: 0, y: 0};

        this._resetTransform();
        this.resize(this.canvas.width, this.canvas.height);
    }

    addLabel(id, text, x, y, fontSize, opacity, ignoreCollisions, padding) {
        fontSize *= this.pxRatio;
        const lineHeight = 1.1 * fontSize;
        const maxLineWidth = 100 * this.pxRatio;

        // try fetching label bitmap from cache, improves performance a lot.
        // TODO: fix bug: bitmap is baked with a specific fontSize. if fontSize changes we still use old cache.
        const cachedLabelBitmap = labelsCache.get(id);


        let bbox;
        let labelBitmap;

        if (cachedLabelBitmap) {
            const labelWidth = cachedLabelBitmap.width / this.viewScale;
            const labelHeight = cachedLabelBitmap.height / this.viewScale;

            bbox = {
                minX: x - labelWidth/2,
                minY: y - labelHeight/2,
                maxX: x + labelWidth/2,
                maxY: y + labelHeight/2,
                width: labelWidth,
                height: labelHeight,
            };

            labelBitmap = cachedLabelBitmap
        } else {
            // TODO: reuse label canvas?
            let labelCanvas;
            let realOffscreen = false;

            if (realOffscreen) {
                labelCanvas = new OffscreenCanvas(128, 128);
            } else {
                labelCanvas = document.createElement("canvas");
                labelCanvas.width = 128;
                labelCanvas.height = 128;
            }
            
            const labelCanvasCtx = labelCanvas.getContext('2d');;

            this._initStyles(labelCanvasCtx)
            labelCanvasCtx.font = `bold ${fontSize}px Noto Sans`;

            const baseX = 128/2; // center
            const baseY = 2 * this.pxRatio;  // add a couple pixels just in case, to avoid clipping at the top.

            bbox = textBbox(labelCanvasCtx, text, baseX, baseY, maxLineWidth, lineHeight)
            translateBbox(bbox, x, y, true)

            const labelWidth = Math.ceil(bbox.width);
            const labelHeight = Math.ceil(bbox.height);

            // resize canvas, but this clears the canvas and its ctx parameters
            labelCanvas.width = labelWidth + 4 * this.pxRatio;  // add a few extra pixels just in case
            labelCanvas.height = labelHeight + 4 * this.pxRatio;  // add a few extra pixels just in case

            this._initStyles(labelCanvasCtx)
            labelCanvasCtx.font = `bold ${fontSize}px Noto Sans`;

            wrapText(labelCanvasCtx, text, labelWidth/2, baseY, maxLineWidth, lineHeight, true);

            labelBitmap = realOffscreen ? labelCanvas.transferToImageBitmap() : labelCanvas;
            labelsCache.set(id, labelBitmap);
        }
        
        let collisionBox = structuredClone(bbox);
        if (padding) {
            padBbox(collisionBox, 12 / this.viewScale, 20 / this.viewScale, true);
        }

        const collides = this.rTree.collides(collisionBox);

        if (!collides || ignoreCollisions) {
            this.rTree.insert(collisionBox);
            this.ctx.globalAlpha = opacity;
            this.ctx.drawImage(
                labelBitmap,
                bbox.minX + (3 / this.viewScale) * this.pxRatio,  // add a few pixels to correct misalignments
                bbox.minY + (3 / this.viewScale) * this.pxRatio,
                bbox.width,
                bbox.height,
            );
        }

        // // draw bbox for debugging
        // this.ctx.globalAlpha = 1;
        // this.ctx.strokeStyle = collides ? '#f00' : '#0f0';
        // this.ctx.beginPath();
        // this.ctx.rect(
        //     collisionBox.minX,
        //     collisionBox.minY,
        //     collisionBox.width,
        //     collisionBox.height,
        // );
        // this.ctx.stroke();

        // if (realOffscreen) {
        //     labelCanvas.convertToBlob().then(blob => {
        //         var reader = new FileReader();
        //         reader.readAsDataURL(blob);
        //         reader.onloadend = function () {
        //             console.log(reader.result);
        //         };
        //     })    
        // } else {
        //     console.log(labelCanvas.toDataURL())
        // }

        return collides;
    }

    resize(width, height) {
        this.pxRatio = window.devicePixelRatio;
        const renderWidth = width * this.pxRatio;
        const renderHeight = height * this.pxRatio;

        this.canvas.width = renderWidth;
        this.canvas.height = renderHeight;

        this.canvas.style.width = `${width}px`;
        this.canvas.style.height = `${height}px`;

        this.clear();
        this.setView();

        this._initStyles(this.ctx);  // because context gets reset on canvas resize?
    }

    _initStyles(ctx) {
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillStyle = '#eee';
        ctx.strokeStyle = '#5555';
        ctx.lineJoin = 'round';
        ctx.lineWidth = 3;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
    }

    _resetTransform() {
        this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    }

    clear() {
        this.ctx.save();
        this._resetTransform();
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.restore(); //restore transforms

        this.rTree = new RBush();
    }

    setView(viewScale, viewCenter) {
        this.viewScale = viewScale ?? this.viewScale;
        this.viewCenter = viewCenter ?? this.viewCenter;
        this._resetTransform();
        this.ctx.translate(-this.viewCenter.x + this.canvas.width / 2, -this.viewCenter.y + this.canvas.height / 2);
        this.ctx.scale(this.viewScale, this.viewScale);
    }
}
