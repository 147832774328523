import { select } from "d3-selection";
import { transition } from 'd3-transition';  // do not remove. required when transition is used in other files.
import { GraphApp } from "./graphapp.js";
import { Node, UndirectedLink } from "./graph/graph.js";
import { CardManager } from "./cardmanager.js";
import { WelcomeCard } from "./cards/welcome.js";
import { ErrorCard } from "./cards/error.js";
import { DataProvider } from "./provider.js";
import { ArtistCard } from "./cards/artist.js";
import { ConnectionCard } from "./cards/connection.js";
import { setupSearch } from "./search.js";
import { randomChoice } from "./utils.js";
import { AudioManager } from "./audiomanager.js";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";


const INITIAL_ARTISTS = [
    "00FQb4jTyendYWaN8pK0wa",
    "04gDigrS5kc9YWfZHwBETP",
    "0du5cEVh5yTK9QJze8zA0C",
    "0oSGxfWSnnOXhD2fKuz2Gy",
    "1HY2Jd0NmPuamShAr6KMms",
    "1KCSPY1glIKqW2TotWuXOR",
    "1vCWHaC5f2uS3yhpwWbIA6",
    "26VFTg2z8YR0cCuwLzESi2",
    "28gNT5KBp7IjEOQoevXf9N",
    "2wY79sveU1sp5g7SokKOiI",
    "2ye2Wgw4gimLv2eAKyk1NB",
    "3AA28KZvwAUcZuOKwyblJQ",
    "3Nrfpe0tUJi4K4DXYWgMUX",
    "4gzpq5DPGxSnKTe4SA8HAU",
    "4nDoRrQiYLoBzwC5BhVJzF",
    "4npEfmQ6YuiwW1GpUmaq3F",
    "4UXqAaa6dQYAk18Lv7PEgX",
    "5INjqkS1o8h1imAzPqGZBb",
    "5Pwc4xIPtQLFEnJriah9YJ",
    "5WUlDfRSoLAfcVSX1WnrxN",
    "5YGY8feqx7naU7z4HrwZM6",
    "69GGBxA162lTqCwzJG5jLp",
    "6eUKZXaKkcviH0Ku9w2n3V",
    "6M2wZ9GZgrQXHCFfjv46we",
    "6qqNVTkY8uBg9cP3Jd7DAH",
    "6X8uzem5Dbl4l2so4CeexD",
    "716NhGYqD1jl2wI1Qkgq36",
    "7jVv8c5Fj3E9VhNjxT4snq",
    "7ltDVBr6mKbRvohxheJ9h1",
    "7n2wHs1TKAczGzO7Dd2rGr",
];

function getInitialArtistFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    let initialArtistId = urlParams.get('artist_id');

    if (initialArtistId) {
        history.replaceState({}, "", "/");  // clear url
        return initialArtistId;
    }
}

async function init() {
    window.addEventListener('unhandledrejection', (event) => {
        event.preventDefault(); // Prevents default handler (would log to console).
        let reason = event.reason;
        
        // Do nothing and hope it's nothing serious
        console.warn(reason)
    });

    resize();
    window.cardManager = new CardManager(select("#cards-panel"));

    const welcomeCard = new WelcomeCard();
    welcomeCard.setLoading();
    window.cardManager.setCard(welcomeCard);

    try {
        window.dataProvider = new DataProvider();
        await window.dataProvider.init();
        window.audioManager = new AudioManager();
    
        window.onload = resize;
        window.onresize = resize;
    
        initGraphApp();
        initSettings();
        setupSearch(artist => goToArtist(artist.id, true), window.dataProvider)
        
        let initialArtistId = getInitialArtistFromUrl();

        if (!initialArtistId) {
            initialArtistId = randomChoice(INITIAL_ARTISTS);
            goToArtist(initialArtistId, false);
            welcomeCard.init(window.serverDataDate);
        } else {
            goToArtist(initialArtistId, true);
        }

    } catch (exception) {
        console.warn(exception);
        if (exception.name == "DatabaseClosedError") {
            showError("Browser not supported.<br>Try musiverkko.com in a different browser.");
        } else {
            showError();
        }
    }
}


function resize() {
    window.graphApp && window.graphApp.sizeChanged();

    var overlaysWidth = select("#overlays-area").node().getBoundingClientRect().width;
    const isMobile = overlaysWidth < 560;
    
    if (isMobile != window.isMobile) {
        if (isMobile) {
            const searchWrapper = select("#artist-search-wrapper").classed("mobile", true);
            // move to a different position in the DOM.
            searchWrapper.remove();
            select("#overlays-area").insert(() => searchWrapper.node(), "#cards-panel");
        } else {
            const searchWrapper = select("#artist-search-wrapper").classed("mobile", false);
            // move to a different position in the DOM.
            searchWrapper.remove();
            select(".top-bar").insert(() => searchWrapper.node(), ".menu-section");
        }
    }

    window.isMobile = isMobile;
};


function initGraphApp() {
    const container = select("#graph-area");
    var debugContainer =  null;

    if (false) { // debug
        debugContainer = select('#debug-box');
        debugContainer.style('display', 'flex');
    }

    window.graphApp = new GraphApp(container, debugContainer);
    window.graphApp.onNodeSelected = onNodeSelected;
    window.graphApp.onLinkSelected = onLinkSelected;
    window.graphApp.onUnselectAll = onUnselectAll;
    window.graphApp.nodeWeight = node => node.obj.weight;
    window.graphApp.linkWeight = link => link.obj.numTracks;
    window.graphApp.getNodeSubgraph = async node => await window.dataProvider.getSubgraph(node.id);
}

function initSettings() {
    const hideLabelsCB = select('#hide-labels');
    hideLabelsCB.on('change', () => {
        window.graphApp.setLabelsVisible(!hideLabelsCB.property('checked'));
    });
    hideLabelsCB.property('checked', false);
}


function goToArtist(artistId, showCard=false) {
    window.graphApp.resetState(showCard);
    window.graphApp.resetView();

    let node = new Node(artistId);

    const artist = window.dataProvider.artists.get(artistId);

    if (artist) {
        node = new Node(artist.id, artist.name, artist)
        window.graphApp.addNode(node);
    }

    window.graphApp.expandNode(node).then(() => {
        if(showCard) showArtistCard(artistId);
    });
}
window.goToArtist = goToArtist;


function artistLink(artistId) {
    return "https://artist.musiverkko.com/" + artistId;
}


function _onConnectedArtistClick(artistId, connectedArtistId) {
    const link = window.graphApp.graph.getLink(artistId, connectedArtistId);
    window.graphApp.selectLink(link);
}

function _onArtistShareClick(artist) {
    const url = artistLink(artist.id);
    if (navigator.share) {
        navigator.share({
            title: `Musiverkko | ${artist.name}`,
            url: url,
        });
    } else if (navigator.clipboard) {
        navigator.clipboard.writeText(url).then(() => {
            showToast("Link copied to clipboard");
        }, (err) => {
            showToast("Error sharing");
        });
    } else {
        showToast("Error sharing");
    }
}


function _onArtistVisitClick(artist) {
    goToArtist(artist.id, true);
}


function onNodeSelected(node) {
    showArtistCard(node.obj.id);
}


function showArtistCard(artistId) {
    const artist = window.dataProvider.getArtist(artistId);
    if (!artist) return;

    const artistCard = new ArtistCard(
        (navigator.clipboard || navigator.share)? _onArtistShareClick : null,
        _onArtistVisitClick,
        _onConnectedArtistClick
    );

    artistCard.setLoading();
    window.cardManager.setCard(artistCard);
    artistCard.init(artist, window.dataProvider);
}
window.showArtistCard = showArtistCard;


function _onArtistClick(artistId) {
    const node = window.graphApp.graph.getNode(artistId);
    window.graphApp.expandNode(node).then(() => {
        window.graphApp.selectNode(node);
    });
}


function onLinkSelected(link) {
    showConnectionCard(link.nodeId1, link.nodeId2);
}


function showConnectionCard(artistId1, artistId2) {
    const connection = window.graphApp.graph.getLink(artistId1, artistId2)?.obj;
    if (!connection) return;

    const connectionCard = new ConnectionCard(_onArtistClick);

    connectionCard.setLoading();
    window.cardManager.setCard(connectionCard);
    connectionCard.init(connection, window.dataProvider, window.audioManager);
}
window.showArtistCard = showArtistCard;


function onUnselectAll(removeCard=true) {
    if (removeCard) {
        removeCurrentCard();
    };

    hideMobileSearch();
}

function removeCurrentCard() {
    window.cardManager.removeCurrentCard();
}
window.removeCurrentCard = removeCurrentCard;


function showHelp() {
    new WelcomeCard()
        .init(window.serverDataDate)
        .then((card) => {
            window.cardManager.setCard(card);
        });
}
window.showHelp = showHelp;


function showMobileSearch() {
    // should only be called in mobile devices.
    
    const searchWrapper = select("#artist-search-wrapper");
    searchWrapper.attr("style", "display: block !important;");

    // focus the search box
    searchWrapper.select("#artist-search-box").node().focus();
}
window.showMobileSearch = showMobileSearch;


function hideMobileSearch() {
    // should only be called in mobile devices.

    const searchWrapper = select("#artist-search-wrapper");
    searchWrapper.attr("style", "");

    // unfocus the search box
    select("body").node().focus();
}
window.hideMobileSearch = hideMobileSearch;


function showError(errorMsg) {
    const errorCard = new ErrorCard();
    errorCard.init(errorMsg).then((card) => {
        window.cardManager.setCard(card);
    });
}
window.showError = showError;


function showToast(msg) {
    Toastify({
        text: msg,
        selector: "overlays-area",
        duration: 3000,
        gravity: "top",
        position: "center",
        style: {
            background: "#63b7f7",
            color: "#242424",
            fontSize: "0.85em",
        },
    }).showToast();
}
window.showToast = showToast;


init();
