const FADE_DURATION = 200;

export class CardManager {
    constructor (container) {
        this.container = container;
        this.currentCard = null;
    }

    setCard(card) {
        this.removeCurrentCard().then(() => {
            this.currentCard = card;
            this.container.append(() => this.currentCard.div.node())
                .style('opacity', 0)
                .style('display', this.currentCard.display)
                .transition().duration(FADE_DURATION)
                .style('opacity', 1);
        });
    }

    refreshCard(card) {
        if (card == this.currentCard) {
            this.container.select("div").remove();
            this.container.append(() => this.currentCard.div.node())
                .style('opacity', 0)
                .style('display', this.currentCard.display)
                .transition().duration(FADE_DURATION)
                .style('opacity', 1);
        }

    }

    removeCurrentCard() {
        return new Promise((resolve, reject) => {
            if (!this.currentCard) return resolve();

            this.currentCard.div.transition().duration(FADE_DURATION)
                .style('opacity', 0)
                .on('end', () => {
                    if (this.currentCard) {
                        // this.currentCard.div.remove();
                        this.container.select("div").remove();
                        this.currentCard.onClose();
                        this.currentCard = null;
                    }
                    return resolve();
                });
          });
    }
}
