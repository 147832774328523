export class Node {
    constructor(id, name, obj) {
        this.id = id;
        this.name = name;
        this.obj = obj;
    }

    copy() {
        return new Node(this.id, this.name, this.obj);
    }
}


export class UndirectedLink {
    constructor(nodeId1, nodeId2, obj) {
        if (nodeId2 < nodeId1)
            [nodeId1, nodeId2] = [nodeId2, nodeId1];

        this.nodeId1 = nodeId1;
        this.nodeId2 = nodeId2;
        this.obj = obj;
    }

    get id() {
        return JSON.stringify([this.nodeId1, this.nodeId2]);
    }

    bind(graph) {
        this.source = graph.getNode(this.nodeId1)  // used by d3-force
        this.target = graph.getNode(this.nodeId2)  // used by d3-force        
    }

    copy() {
        return new UndirectedLink(this.nodeId1, this.nodeId2, this.obj);
    }
}


export class UndirectedLinksMap extends Map{
    // get2(artistId1, artistId2) {
    //     if (artistId2 < artistId1)
    //         [artistId1, artistId2] = [artistId2, artistId1];

    //     const id = Connection.buildId(artistId1, artistId2);
    //     return this.get(id);
    // }

    // set(connection) {
    //     return super.set(connection.id, connection);
    // }
    add(link) {
        return super.set(link.id, link);
    }
}


export class NodesMap extends Map{
    // get2(artistId1, artistId2) {
    //     if (artistId2 < artistId1)
    //         [artistId1, artistId2] = [artistId2, artistId1];

    //     const id = Connection.buildId(artistId1, artistId2);
    //     return this.get(id);
    // }

    add(node) {
        return super.set(node.id, node);
    }
}


export class Graph {
    constructor() {
        this._nodes = new NodesMap();
        this._links = new UndirectedLinksMap();
        this._nodeNeighbors = new Map();
        this._expandedNodes = new Set();
    }

    get n() {
        return this._nodes.size;
    }

    get m() {
        return this._links.size;
    }

    addNode(node) {
        if (this._nodes.has(node.id)) {
            return
        }

        this._nodes.add(node);
        this._nodeNeighbors.set(node.id, new Set());
    }

    addLink(link) {
        if (this._links.has(link.id)) {
            return
        }

        link.bind(this);
        this._links.add(link);
        this._nodeNeighbors.get(link.nodeId1).add(this.getNode(link.nodeId2));
        this._nodeNeighbors.get(link.nodeId2).add(this.getNode(link.nodeId1));
    }

    copy() {
        const newGraph = new Graph();
        this._nodes.forEach(node => newGraph.addNode(node.copy()));
        this._links.forEach(link => {
            newGraph.addLink(link.copy());
            link.bind(newGraph);  // bind to the new graph
        });
        return newGraph;
    }

    merge(otherGraph) {
        otherGraph._nodes.forEach(node => this.addNode(node));
        otherGraph._links.forEach(link => {
            this.addLink(link);
            link.bind(this);  // bind to the new graph
        });
    }

    getNode(nodeId) {
        return this._nodes.get(nodeId);
    }

    getLink(nodeId1, nodeId2) {
        if (nodeId2 < nodeId1)
            [nodeId1, nodeId2] = [nodeId2, nodeId1];

        const linkId = JSON.stringify([nodeId1, nodeId2]);
        return this._links.get(linkId);
    }

    // static fromMapsObj(artistsMap, connectionsMap) {
    //     return new ArtistsGraph(
    //         ArtistsMap.fromMapObj(artistsMap),
    //         ConnectionsMap.fromMapObj(connectionsMap),
    //     );
    // }

    // merge(otherGraph) {
    //     otherGraph.artists.forEach(artist => this.artists.set(artist));
    //     otherGraph.connections.forEach(connection => this.connections.set(connection));
    // }

    nodeNeighbors(nodeId) {
        return this._nodeNeighbors.get(nodeId);
    }

    neighborhoodGraph(nodeId) {
        const subgraph = new Graph();
        const node = this.getNode(nodeId)

        subgraph.addNode(node);

        const nodeNeighbors = this.nodeNeighbors(nodeId);

        Array.from(nodeNeighbors).forEach(neighborNode => {
            subgraph.addNode(neighborNode);
            subgraph.addLink(this.getLink(node.id, neighborNode.id));
        });

        Array.from(nodeNeighbors).forEach(neighborNode => {
            Array.from(this.nodeNeighbors(neighborNode.id)).forEach(neighborNode2 => {
                if (nodeNeighbors.has(neighborNode2)) {
                    subgraph.addLink(this.getLink(neighborNode.id, neighborNode2.id));
                }
            });
        });

        return subgraph;
    }

    // get nodes() {
    //     return this.artists;
    // }

    // get links() {
    //     return this.connections;
    // }

    get nodesArray() {
        return Array.from(this._nodes.values());
    }

    get linksArray() {
        return Array.from(this._links.values());
    }

    markNodeExpanded(nodeId) {
        this._expandedNodes.add(nodeId);
    }

    nodeIsExpanded(nodeId) {
        return this._expandedNodes.has(nodeId);
    }
}
