import Dexie from "dexie";
import { Artist, Connection, Track } from "./models";

export class LocalDB {
    constructor() {
        this.db = new Dexie("local-cache");
        this.db.version(1).stores({
            artists: "id",
            tracks: "id",
            connections: "id",
            expandedArtists: "id",
        });
    }

    async transaction() {
        return (await this.dbPromise).transaction();
    }

    // artists

    async putArtists(artists) {
        await this.db.artists.bulkPut(artists.map(artist => artist.data()));
    }

    async getArtists() {
        const artistsData = await this.db.artists.toArray();
        const artists = artistsData.map(artistData => new Artist(artistData))

        return artists;
    }

    // connections

    async putConnections(connections) {
        await this.db.connections.bulkPut(connections.map(connection => connection.data()));
    }

    async getConnections() {
        const connectionsData = await this.db.connections.toArray();
        const connections = connectionsData.map(connectionData => new Connection(connectionData))

        return connections;
    }

    // tracks

    async putTracks(tracks) {
        await this.db.tracks.bulkPut(tracks.map(track => track.data()));
    }

    async getTracks() {
        const tracksData = await this.db.tracks.toArray();
        const tracks = tracksData.map(trackData => new Track(trackData))

        return tracks;
    }

    // Expanded Artist IDs

    async markArtistExpanded(artistId) {
        await this.db.expandedArtists.put({ id: artistId });
    }

    async getExpandedArtistIds() {
        return await this.db.expandedArtists.toCollection().keys();
    }
}
